import React, { Component }  from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Power1 } from 'gsap';
import { TimelineMax } from 'gsap/TweenMax';

import * as popupActions from 'redux/actions/popupActions';
import * as gameActions from 'redux/actions/gameActions';
import * as locale from 'utils/locale';
import * as strapi from 'utils/strapi';

import TransitionLink from 'components/general/TransitionLink';

import './Icon.scss';
import GoButton from '../../../general/GoButton';



const mapDispatchToProps = dispatch => ({
    openPopup: (content, options = {}) => dispatch(popupActions.openPopup(content, options)),
    closePopup: () => dispatch(popupActions.closePopup()),
    resetGame: (gameSlug) => dispatch(gameActions.resetGame(gameSlug, {})),
    openGame: (gameId) => dispatch(gameActions.openGame(gameId, {})),
});

class Icon extends Component {
    static defaultProps = {
        status: 'enabled', // enabled, disabled or highlighted
        animated: null,
	};

    constructor(props) {
        super(props);

        this.icon = React.createRef();
        this.tl = null;

        this.state = {
            status: this.props.status,
            animated: this.props.animated !== null ? this.props.animated : this.props.status !== 'disabled',
        }
    }

	render() {
        let style = {};

        style.left = (this.props.mapPositionX * 100) + '%';
        style.top = (this.props.mapPositionY * 100) + '%';
        
        let icon = this.props.icon;

        switch(this.state.status) {
            case 'enabled' :
                icon = this.props.icon; 
                break;
            case 'disabled' :
                icon = this.props.icon3; 
                break;
            case 'highlighted' :
                icon = this.props.icon2; 
                break;
            default :
                icon = this.props.icon; 
                break;
        }

        return (
            <div className="icon" onClick={this.openPopup.bind(this)} style={style} ref={this.icon}>
                <div className="icon-container">
                    <div className="animated-content">
                        <div className="icon-content">
                            <img src={icon} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    openPopup() {
        this.props.openPopup(
            <div className="game-popup">
                {/*
                <img src={this.props.icon2} alt="" style={{
                    display:'block',
                    margin:'auto',
                    height:'5em'
                }}/>
                */}
                <div
                    className="game-popup-description"
                    dangerouslySetInnerHTML={{__html: strapi.mdToHtml(locale.textObj(this.props.description))}}
                />
            </div>,
            {
                title: locale.textObj(this.props.name),
                overlayClick: true,
                responsiveElementOptions: { 
                    width: "35%",
                },
                actionButtons: [
                    {
                        type: 'back',
                        action: () => {
                            this.props.closePopup();
                        }
                    },
                    {
                        content: (
                            <TransitionLink to={locale.getUrl('/jeu/:slug', {slug: locale.textObj(this.props.urlSlug)})} onTransitionStart={this.onTransitionStart.bind(this)}>
                                {/*<GameButton><Text slug="general.go"/></GameButton>*/}
                                <GoButton color="yellow" width="11em" />
                            </TransitionLink>
                        )
                    }
                ]
            }
        );
    }

    onTransitionStart(end) {
        this.props.closePopup();
        this.props.resetGame(this.props.slug);
        this.props.onTransitionStart(() => {
            this.props.openGame(this.props.id);
            end();
        });
    }

    componentDidMount() {
        if(this.state.animated) {
            this.tl = new TimelineMax({repeat:-1, yoyo:true});
    
            let time = Math.random() * 1.5 + 0.5;
            let range = time;
            let direction = Math.round(Math.random()) === 0 ? '-' : '+';
    
            this.tl.from(this.icon.current, time, {top:direction+"="+range+"%", ease:Power1.easeInOut});
        }
    }

    componentWillUnmount() {
        if(this.tl) {
            this.tl.kill();
        }
    }
}

export default withRouter(connect(null, mapDispatchToProps)(Icon));