import React, { Component }  from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { TweenLite } from "gsap/TweenMax";

import { AnimatedElements, animateIn, animateOut } from 'components/general/AnimatedElements';
import * as siteActions from 'redux/actions/siteActions';
import * as locale from 'utils/locale';

import TransitionLink from 'components/general/TransitionLink';
import ResponsiveElement from 'components/general/ResponsiveElement';
import Text from 'components/general/Text';
import Icon from './Icon/Icon';

import './Map.scss';
import GoButton from '../../general/GoButton';

const mapStateToProps = (state, ownProps) => {
	return {
		...state.gameReducer
	}
}

const mapDispatchToProps = dispatch => ({
    setUrls: (urls) => dispatch(siteActions.setUrls(urls)),
});

class Map extends Component {
    constructor(props) {
        super(props);

        this.animatedElements = [];
        this.bg = null;
        this.map = null;
        this.overlay = null;

        this.state = {
            overlay: null,
        };
    }

	render() {
        const { overlay } = this.state;

        return (
            <div className={"game-map" + (overlay !== null ? ' overlay-' + overlay : '')}>
                <div className="map-bg" ref={bg => this.bg = (bg)}>
                    <ResponsiveElement ratio={2000/1125} height="100%" position={["50%", "50%"]} anchor={["50%", "50%"]} maxWidth="100%">
                        <img src="/images/game/map/map_main.png" className="map-main" ref={map => this.map = (map)} alt="Carte de jeu"/>
                        {this.renderGameIcons()}
                    </ResponsiveElement>
                </div> 
                {this.renderOverlay()}
                {this.renderBackButton()}
            </div>
        );
    }

    renderOverlay() {
        const { overlay } = this.state;

        if(overlay === null) {
            return null;
        }

        return (
            <AnimatedElements
                animatedElements={this.animatedElements}
                animation='slide-up'
                autoStart={true}
                durationOut={1}
                ref={overlay => this.overlay = (overlay)}
            >
                <div className="map-overlay">
                    <div className="overlay-character">
                        <img src="/images/characters/anouk_full.svg" alt="Anouk"/>
                    </div>
                    <div className="overlay-text">
                        <div className="text-container">
                            <div className="text-content">
                                <p>
                                    <Text slug={"map."+overlay}/>
                                </p>
                                <div className="grow-hover center">
                                    <GoButton
                                        onClick={() => {this.onOverlayButtonClick(true)}}
                                        width="8em"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AnimatedElements>
        )
    }

    renderGameIcons() {
        let icons = [];

        let games = this.props.games;

        games.forEach((game, i) => {
            let status = 'enabled';

            if(this.props.completion.games.includes(game.id)) {
                status = 'highlighted';
            }

            icons.push(
                <AnimatedElements animatedElements={this.animatedElements} animatedClass="animated-content" key={i}>
                    <Icon {...game} status={status} onTransitionStart={this.onTransitionStart.bind(this)} />
                </AnimatedElements>
            );
        });

        return (
            <div className="icons">
                {icons}
            </div>
        )
    }

    componentDidMount() {
        animateIn(this.animatedElements);
        TweenLite.fromTo(this.bg, 0.6, {opacity: 0}, {opacity: 1});
        this.props.setUrls(locale.getUrls('/jeu'));

        // Keep the unique values in the completion array
        let gameCompletion = [];
        this.props.completion.games.forEach((gameId) => {
            if(!gameCompletion.includes(gameId)) {
                gameCompletion.push(gameId);
            }
        });

        // Check if the overlay should appear
        if(gameCompletion.length === 0) {
            this.setState({
                overlay: 'start',
            });
        } else if(gameCompletion.length === this.props.games.length) {
            this.setState({
                overlay: 'end',
            });
        }
    }

    renderBackButton() {
        return (
            <AnimatedElements animatedElements={this.animatedElements} animation="slide-right">
                <TransitionLink to="/" onTransitionStart={this.onTransitionStart.bind(this)}>
                    <div className={"btn-home grow-hover"}></div>
                </TransitionLink>
            </AnimatedElements>
        )
    }

    onOverlayButtonClick() {
        animateOut([this.overlay], {
            onComplete: () => {
                this.setState({
                    overlay: null,
                });
            }
        });
    }

    onTransitionStart(end) {
        TweenLite.to(this.bg, 0.6, {opacity: 0});
        animateOut(this.animatedElements, {
            onComplete: () => {
                end();
            }
        });
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Map));