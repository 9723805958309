import React, { Component }  from 'react';
import TransitionLink from 'components/general/TransitionLink';
import * as locale from 'utils/locale';

import './ExitButton.scss';

class ExitButton extends Component {
	render() {
        return ( 
            <TransitionLink
                className="exit-button grow-hover"
                to={locale.getUrl("/jeu")}
                onTransitionStart={this.props.onTransitionStart}
                key="return"
            >
                <div className="exit-button__icon"></div>
            </TransitionLink>
        );
    }
}

export default ExitButton;