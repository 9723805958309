import React, { Component } from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {
	return {
		resolution: state.gameReducer.resolution
	}
}

class ResponsiveElement extends Component {
	static defaultProps = {
		ratio: null,
		width: null, 
		height: null,
		minWidth: "0%",
		maxWidth: null,
		minHeight: "0%",
		maxHeight: null,
		top: null,
		bottom: null,
		left: null,
		right: null,
		anchorX: "0%",
		anchorY: "0%",
		anchor: null,
		position: null,
		setFontSize: true,
		cssPosition: 'absolute',
		fontSizeRef: 'self',
		autoAdjust: null,
		//minFontSize: 4,
	};

	constructor(props) {
		super(props);
		this.content = null;
		this.forceSize = false;

		this.state = {
			width: null,
			height: null,
		}
	}
	
	render() {
		const props = this.getRemainingProps();

		props.style = {
			...this.getContainerStyle(),
			...props.style,
		}

		return (
			<div {...props}  ref={ref => this.content = ref} />
		);
	}

	componentDidUpdate() {
		let {autoAdjust} = this.props;

		// Auto autoAdjust
		if(autoAdjust && !this.forceSize) {
			this.adjustSize();
		} else {
			this.forceSize = false;
		}		
	}

	adjustSize() {
		let {autoAdjust} = this.props;

		if(typeof autoAdjust === 'string') {
			autoAdjust = this.content.getElementsByClassName(autoAdjust)[0];
		}

		let contentHeight = autoAdjust.clientHeight;
		let contentWidth = autoAdjust.clientWidth;

		// Get container size
		autoAdjust.style.height = '100%';
		autoAdjust.style.width = '100%';

		let containerHeight = autoAdjust.clientHeight;
		let containerWidth = autoAdjust.clientWidth;

		autoAdjust.style.height = 'auto';
		autoAdjust.style.width = 'auto';

		// Get current size		
		if(containerHeight < contentHeight || containerWidth < contentWidth) {
			this.content.style.width = parseFloat(this.content.style.width) * 1.1 + 'px';
			this.content.style.height = parseFloat(this.content.style.height) * 1.1 + 'px';

			this.adjustSize();
		} else {
			this.forceSize = true;

			this.setState({
				width: parseFloat(this.content.style.width),
				height: parseFloat(this.content.style.height) - containerHeight + contentHeight, // Shrink
			});
		}
	}

	getRemainingProps() {
		const props = Object.assign({}, this.props);
		delete props.ratio;
		delete props.width;
		delete props.height;
		delete props.minWidth;
		delete props.maxWidth;
		delete props.minHeight;
		delete props.maxHeight;
		delete props.top;
		delete props.bottom;
		delete props.left;
		delete props.right;
		delete props.anchorX;
		delete props.anchorY;
		delete props.dispatch;
		delete props.resolution;
		delete props.position;
		delete props.anchor;
		delete props.setFontSize;
		delete props.fontSizeRef;
		delete props.cssPosition;
		delete props.autoAdjust;

		return props;
	}

	getContainerStyle() {
		let size = this.calculateSize();
		let position = this.calculatePosition(size);
		
		let style = {
			'position': this.props.cssPosition,
			...this.pixelate({...size}),
			...this.pixelate({...position}),
		}

		let fontSize;

		if(this.props.setFontSize) {
			switch(this.props.fontSizeRef) {
				case 'self':
				case 'height':
					fontSize = (size.height / 100);
					break;
				case 'width':
					fontSize = (size.width / 100);
					break;
				case 'window-height':
					fontSize = (this.props.resolution.y / 100);
					break;
				case 'window-width':
					fontSize = (this.props.resolution.x / 100);
					break;
				default:
					fontSize = (size.height / 100);
					break;
			}
		}

		//style['fontSize'] = Math.max(this.props.minFontSize, fontSize) + 'px';
		style['fontSize'] = fontSize + 'px';

		return style;
	}

	pixelate(obj) {
		for(let key in obj) {
			obj[key] = obj[key] + 'px';
		}

		return obj;
	}

	percentToPixels(value, pixels) {
		if(value === null) return null;

		if(typeof value === 'string' && value.includes("%")) {
			return parseFloat(value) * pixels / 100;
		} else {
			return parseFloat(value);
		}
	}

	calculatePosition(size) {
		let {
			top,
			bottom,
			left,
			right,
			resolution,
			anchorX,
			anchorY,
			anchor,
			position,
		} = this.props;

		if(position) {
			top = position[1];
			left = position[0];
		}

		if(anchor) {
			anchorX = anchor[0];
			anchorY = anchor[1];
		}

		top = this.percentToPixels(top, resolution.y);
		bottom = this.percentToPixels(bottom, resolution.y);
		left = this.percentToPixels(left, resolution.x);
		right = this.percentToPixels(right, resolution.x);
		anchorX = parseFloat(anchorX);
		anchorY = parseFloat(anchorY);

		let positions = {};

		if(top !== null) {
			positions['top'] = (top - anchorY * size.height / 100);
		}

		if(bottom !== null) {
			positions['bottom'] = (bottom - anchorY * size.height / 100);
		}

		if(left !== null) {
			positions['left'] = (left - anchorX * size.width / 100);
		}

		if(right !== null) {
			positions['right'] = (right - anchorX * size.width / 100);
		}

		return positions;
	}

	calculateSize() {
		let {
			ratio,
			width,
			height,
			minWidth,
			maxWidth,
			minHeight,
			maxHeight,
			resolution,
		} = this.props;

		if(this.state.width && this.state.height && this.forceSize) {
			return {
				width: this.state.width,
				height: this.state.height,
			}
		}

		width = this.percentToPixels(width, resolution.x);
		minWidth = this.percentToPixels(minWidth, resolution.x);
		maxWidth = this.percentToPixels(maxWidth, resolution.x);
		height = this.percentToPixels(height, resolution.y);
		minHeight = this.percentToPixels(minHeight, resolution.y);
		maxHeight = this.percentToPixels(maxHeight, resolution.y);
    
		// Get ratio if width and height are set
		if(!ratio && width !== null && height !== null) {			return {
				width: this.constraintValue(width, minWidth, maxWidth),
				height: this.constraintValue(height, minHeight, maxHeight),
			}
		} else if (ratio && width !== null) {
			return this.constraintRect(width, width / ratio, minWidth, maxWidth, minHeight, maxHeight);
		} else if(ratio && height !== null) {
			return this.constraintRect(height * ratio, height, minWidth, maxWidth, minHeight, maxHeight);
		} else {
			throw new Error("The element should have at least a Width or a Height paired with a ratio, or you should specify an height and a width");
		}
	}

	constraintValue(current, min = null, max = null) {
		if(min) {
			current = Math.max(current, min);
		}

		if(max) {
			current = Math.min(current, max);
		}

		return current;
	}

	constraintRect(width, height, minWidth, maxWidth, minHeight, maxHeight) {
		// Validate min
		let minRatio = 0;
		let nMinRatio;

		if(minWidth !== null && width < minWidth) {
			nMinRatio = minWidth / width;
			minRatio = nMinRatio > minRatio ? nMinRatio : minRatio;
		}

		if(minHeight !== null && height < minHeight) {
			nMinRatio = minHeight / height;
			minRatio = nMinRatio > minRatio ? nMinRatio : minRatio;
		}

		if(minRatio !== 0) {
			width = width * minRatio;
			height = height * minRatio;
		}

		// Validate max
		let maxRatio = 1;
		let nMaxRatio;

		if(maxWidth !== null && width > maxWidth) {
			nMaxRatio = maxWidth / width;
			maxRatio = nMaxRatio < maxRatio ? nMaxRatio : maxRatio;
		}

		if(maxHeight !== null && height > maxHeight) {
			nMaxRatio = maxHeight / height;
			maxRatio = nMaxRatio < maxRatio ? nMaxRatio : maxRatio;
		}

		if(maxRatio !== 1) {
			width = width * maxRatio;
			height = height * maxRatio;
		}

		return {
			width: width,
			height: height,
		}
	}
}

export default connect(mapStateToProps)(ResponsiveElement);