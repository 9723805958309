import React, { Component }  from 'react';

import ResponsiveElement from 'components/general/ResponsiveElement';

import './LevelDisplay.scss';

class LevelDisplay extends Component {
	render() {
        return (
            <ResponsiveElement right="6%" top="50%" height="60%" maxHeight="700px" anchorY="50%" ratio={192/650} maxWidth="14%" className="level-display">
                {this.renderLevels()}
            </ResponsiveElement>
        );
    }

    renderLevels() {
        let {currentLevel} = this.props;

        let combinationDiv = [];

        for(let i = 0; i < 3; i++) {
            let completed = i < currentLevel ? <img src="/images/game/lock/step_number_completed.png" alt="" className="completed" /> : null;

            combinationDiv.push(
                <div className={"level level-" + i} key={i}>
                    <div className="level-container">
                        <div className="level-content">
                            {completed}
                        </div>
                    </div>
                </div>
            );
        }

        return combinationDiv;
    }
}

export default LevelDisplay;