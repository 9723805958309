import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

/**
 * Wraps the React Router Link component and creates a delay after the link is clicked.
 */
class TransitionLink extends Component {
	static propTypes = {
		onTransitionStart: PropTypes.func,
	};

	static defaultProps = {
		onTransitionStart: (end) => {}
	};

	/**
	 * Called when the link is clicked
	 *
	 * @param {Event} e
	 */
	handleClick(e) {
		const { replace, to, onTransitionStart, history } = this.props;

		if (e.defaultPrevented) {
			return;
		}
		e.preventDefault();

		onTransitionStart(() => {
			if (replace) {
				history.replace(to);
			} else {
				history.push(to);
			}
		});
	};

	render() {
		const props = Object.assign({}, this.props);
		delete props.onTransitionStart;
		delete props.staticContext;
		delete props.history;
		delete props.location;
		delete props.match;

		return (
			<Link {...props} onClick={this.handleClick.bind(this)} />
		);
	}
}

export default withRouter(TransitionLink);