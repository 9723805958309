import React, { Component }  from 'react';
import { connect } from 'react-redux';
import { TweenLite } from "gsap/TweenMax";

import { getGameFromState } from 'utils/games/gameUtils';
import * as gameActions from 'redux/actions/gameActions';

import Main from './Main/Main';

import './DeplacementsGame.scss';

const gameSlug = 'en-route-vers-le-secondaire';

const mapStateToProps = (state, ownProps) => {
    return {
        ...getGameFromState(gameSlug, state),
    }
}

const mapDispatchToProps = dispatch => ({
    resetGame: (status = {}) => dispatch(gameActions.resetGame(gameSlug, status)),
});

class DeplacementsGame extends Component {
    static defaultProps = {
        screen: 0
	};

    constructor(props) {
        super(props);

        this.animatedElements = [];
        this.bg = null;
    }

	render() {
        return (
            <div className="deplacements-game">
                <div className="game-bg" ref={bg => this.bg = (bg)}></div>
                {this.renderGame()}
            </div>
        );
    }

    UNSAFE_componentWillMount() {
        this.props.resetGame();
    }

    componentDidMount() {
        TweenLite.fromTo(this.bg, 0.6, {opacity: 0}, {opacity: 1});
    }

    renderGame() {
        switch(this.props.screen) {
            case 0 :
                return <Main />
            default :
                return <Main />
        }
    }

    onTransitionStart(end) {
        TweenLite.to(this.bg, 0.6, {opacity: 0, delay: 0.5});
    }

    resetGame(e) {
        e.preventDefault();
        this.props.resetGame();
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeplacementsGame);