import React, { Component }  from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import * as gameActions from 'redux/actions/gameActions';
import { getGameFromState, getPopupDispatch } from 'utils/games/gameUtils';
import { AnimatedElements, animateIn, animateOut } from 'components/general/AnimatedElements';
import * as strapi from 'utils/strapi';
import * as locale from 'utils/locale';

import GoButton from 'components/general/GoButton';

import './Main.scss';
import ExitButton from '../../ExitButton/ExitButton';

const gameSlug = 'agenda';

const mapStateToProps = (state, ownProps) => {
    return {
        ...getGameFromState(gameSlug, state),
    }
}

const mapDispatchToProps = dispatch => ({
    updateGameStatus: (status = {}) => dispatch(gameActions.updateGameStatus(gameSlug, status)),
    resetGame: () => dispatch(gameActions.resetGame(gameSlug)),
    closeMenu: (menuItems) => dispatch(gameActions.closeMenu(menuItems)),
    ...getPopupDispatch(dispatch),
});

class Main extends Component {
    constructor(props) {
        super(props);

        this.animatedElements = [];
        this.bg = null;
        this.currentQuestion = null;
        this.answers = null;
    }

	render() {
        return (
            <div className="agenda-game-main">
                <AnimatedElements animatedElements={this.animatedElements} skipIntro animation="slide-down" animationClass="question">
                    {this.renderQuestion()}
                </AnimatedElements>
                <div className="ui">
                    <ExitButton onTransitionStart={this.onTransitionStart.bind(this)} />
                </div>
            </div>
        );
    }

    renderQuestion() {
        let { currentQuestion, questions } = this.props;
        let question = questions[currentQuestion];

        if(question !== this.currentQuestion) {
            this.currentQuestion = question;
            this.answer = Math.round(Math.random()) === 1;
        }

        const questionTextHtml = strapi.mdToHtml(locale.textObj(question.questionText));

        return (
            <div className="question">
                <div className="question-container">
                    <div className="question-content">
                        <div className="title">
                            { locale.textObj(question.questionTitle) }
                        </div>
                        <div className="text" dangerouslySetInnerHTML={{__html: questionTextHtml}} />
                    </div>
                </div>
                <div className="answers">
                    {question.answers.map((answer, index) => this.renderAnswer(question, answer, index))}
                </div>
            </div>
        );
    }

    renderAnswer(question, answer, index) {
        return (
            <div className="answer" key={index}>
                <div className="title">
                    { locale.textObj(answer.text) }
                </div>
                
                { this.renderAgendaImage(question, answer) }

                <div className="button">
                    <GoButton onClick={() => this.onAnswerClick(question, answer)} />
                </div>
            </div>
        )
    }

    renderAgendaImage(question = null, answer = null) {
        const layers = { ...this.props.layers };

        // Add current question image
        if(question) {
            if(!question.programmaticImage) {
                layers[question.imageLayer] = locale.textObj(answer.image);
            } else {
                layers[question.imageLayer] = this.getProgrammaticImage(question, answer);
            }
        }

        return (
            <div className="agenda-result-image">
                <img src="/images/game/agenda/bg.png" alt="bg-agenda" />
                {
                    Object.values(layers).map((imageUrl, i) => {
                        return <img src={imageUrl} key={i} className="agenda-layer" alt="agenda-layer" />;
                    })
                }
            </div>
        );
    }

    onAnswerClick(question, answer) {
        let gameStatus = {};

        // Compute answer image
        if(question.programmaticImage) {
            gameStatus['layers'] = {
                ...this.props.layers,
                [question.imageLayer]: this.getProgrammaticImage(question, answer),
            };
        } else {
            gameStatus['layers'] = {
                ...this.props.layers,
                [question.imageLayer]: locale.textObj(answer.image),
            };
        }

        // Add answers to game status
        gameStatus['answers'] = {
            ...this.props.answers,
            [question.slug]: answer.id,
        };

        // Update game status
        this.props.updateGameStatus(gameStatus);

        // Show conclusion
        const conclusionTextHtml = strapi.mdToHtml(locale.textObj(question.conclusionText));

        this.props.openPopup(
            <div className="agenda-answer-popup-content" dangerouslySetInnerHTML={{__html: conclusionTextHtml}} />, 
            {
                title: locale.textObj(question.conclusionTitle),
                responsiveElementOptions: {
                    ratio: null,
                    width: '40%',
                    height: '40%',
                },
                actionButtons: [
                    {
                        content: <GoButton color="blue" width="10em" onClick={this.nextQuestion.bind(this)} />,
                    },
                ]
            }
        );
    }

    getProgrammaticImage(question, answer) {
        const { answers } = this.props;
        let imageName = '';

        if(question.slug === 'color') {
            imageName += 's3';

            if(answers['text'] === 0) {
                imageName += '_simple';
            } else {
                imageName += '_full';
            }

            if(answers['todo'] === 0) {
                imageName += '_1';
            } else {
                imageName += '_0';
            }

            if(answer.id === 0) {
                imageName += '_1';
            } else {
                imageName += '_0';
            }
        }

        return '/images/game/agenda/' + imageName + '.png';
    }

    nextQuestion() {
        let { currentQuestion, questions } = this.props;

        if(currentQuestion < questions.length - 1) {
            this.props.closePopup();
            this.props.updateGameStatus({
                currentQuestion: currentQuestion + 1,
            });
        } else {
            this.openResultPopup();
        }
    }

    openResultPopup() {
        this.props.openPopup(
            <div className="agenda-result-popup-content" style={{height: '100%'}}>
                { this.renderAgendaImage() }
            </div>, 
            {
                responsiveElementOptions: {
                    ratio: 4 / 3,
                    maxHeight: '700px',
                    maxWidth: '80%',
                    width: '80%',
                    height: '80%',
                },
                actionButtons: [
                    {
                        content: <GoButton color="blue" width="10em" onClick={this.openClosingPopup.bind(this)} />,
                    },
                ]
            }
        );
    }

    openClosingPopup() {
        const textHtml = strapi.mdToHtml(strapi.data.getText('agenda.end'));

        this.props.openPopup(
            <div className="agenda-answer-popup-content" dangerouslySetInnerHTML={{__html: textHtml}} />, 
            {
                responsiveElementOptions: {
                    ratio: null,
                    width: '40%',
                    height: '55%',
                },
                actionButtons: [
                    {
                        type: 'home',
                        action: () => {
                            this.onTransitionStart(() => {
                                this.props.history.push(locale.getUrl("/jeu"));
                            });
                        }
                    },
                    {
                        type: 'restart',
                        action: () => {
                            this.resetGame();
                        }
                    },
                ]
            }
        );
    }

    componentDidMount() {
        animateIn(this.animatedElements);
        //TweenLite.fromTo(this.bg, 0.6, {opacity: 0}, {opacity: 1});
    }

    resetGame() {
        this.props.resetGame();
        this.props.closePopup();
        //this.setupStep(this.props.step);
    }

    onTransitionStart(end) {
        this.props.closeMenu();
        this.props.closePopup();
        //TweenLite.to(this.bg, 0.6, {opacity: 0, delay: 0.5});
        animateOut(this.animatedElements, {
            onComplete: () => {
                end();
            }
        });
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));