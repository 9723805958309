import { Component }  from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import * as gameActions from 'redux/actions/gameActions';

import { getPopupDispatch } from 'utils/games/gameUtils';

const mapDispatchToProps = dispatch => ({
	...getPopupDispatch(dispatch),
	closeMenu: () => dispatch(gameActions.closeMenu()),
});

class AppContainer extends Component {
	componentDidMount() {
		this.unblock = this.props.history.block((location, action) => {
			this.props.closePopup();
			this.props.closeMenu();
		});

		/*this.unlisten = this.props.history.listen(function (location) {
			window.ga('set', 'page', location.pathname + location.search);
			window.ga('send', 'pageview');
		});*/
	}
	componentWillUnmount() {
		this.unblock();
		//this.unlisten();
	}
	render() {
		return this.props.children;
	}
}

export default withRouter(connect(null, mapDispatchToProps)(AppContainer));