import React from 'react';

function FloatingButton(props) {
    const { children, animation } = props;

    return (
        <div className={"floating-button-" + animation}>
            {children}
        </div>
    )
}

FloatingButton.defaultProps = {
    animation: 1,
}

export default FloatingButton;