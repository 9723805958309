import showdown from "showdown";
import targetblank from "showdown-target-blank";
import * as _ from "lodash";

import * as locale from 'utils/locale';
import * as str from 'utils/str';

class StrapiData {
    constructor() {
        this.data = {};
        this.texts = {};
    }

    getText(key, defaultValue = null) {
        let keyOnly = this.findGetParameter('showKeys');
        defaultValue = defaultValue || key;

        if(parseInt(keyOnly) === 1) {
            return key;
        }

        let textObj = _.get(this.texts, key, {});

        return locale.textObj(textObj);
    }

    init(data) {
        this.data = data;

        let availableLangs = locale.getAvailableLangs();

        // Setup text as key => value
        data.texts.forEach((text, i) => {
            let textObj = {};

            for(let u = 0; u < availableLangs.length; u++) {
                let l = availableLangs[u];

                if(l === locale.reboungLang) {
                    textObj[l] = text['content'];
                } else {
                    textObj[l] = _.get(text, locale.langSuffix('content', l));
                }
            }

            this.texts[text.key] = textObj;
        });
    }

    findGetParameter(parameterName) {
        let result = null,
            tmp = [];

        window.location.search
            .substr(1)
            .split("&")
            .forEach(function (item) {
              tmp = item.split("=");
              if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
            });
        return result;
    }
}

export let data = new StrapiData();

export function getImageUrl(url) {
    return process.env.REACT_APP_STRAPI_ASSETS + url;
}

export function slideToHtml(slide) {
    let content = {};

    locale.getAvailableLangs().forEach(lang => {
        content[lang] = localizedSlideToHtml(slide, lang);
    });

    return content;
}

export function handleNbsp(content) {
    // Replace ": " to non-breaking space
    content = content.replace(/: /g, ':&nbsp;');

    // Replace " :" to non-breaking space
    content = content.replace(/ :/g, '&nbsp;:');

    // Replace "« " to non-breaking space
    content = content.replace(/« /g, '«&nbsp;');

    // Replace " »" to non-breaking space
    content = content.replace(/ »/g, '&nbsp;»');

    // Replace " !" to non-breaking space
    content = content.replace(/ !/g, '&nbsp;!');

    // Replace " ?" to non-breaking space
    content = content.replace(/ \?/g, '&nbsp;?');

    return content;
}

export function mdToHtml(content) {
    let converter = new showdown.Converter({extensions: [targetblank]});

    // Replace single new lines to <br/>
    content = content.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br/>$2');

    content = converter.makeHtml(content);

    return handleNbsp(content);
}

export function localizedSlideToHtml(slide, lang) {
    let langCode = str.capitalizeFirstLetter(lang);
    let slideContent = slide['content' + langCode] || slide.content;
    let content = mdToHtml(slideContent);

    if(slide.isSimpleContent) {
        let className = 'simple-content';

        if(slide.image) {
            className += ' image-';
            className += slide.imagePosition || 'left';
        }

        if(slide.textAlign) {
            className += ' align-';
            className += slide.textAlign || 'auto';
        }
        
        let html = '<div class="'+className+'">';

        let image = slide['image' + langCode] || slide.image;
        if(image) {
            html += '<img src="'+ getImageUrl(image.url) + '"/>';
        }
        
        html += '<div class="html-content">';
        html += content;
        html += '</div>';
        html += '</div>';

        return html;
    }

    return content;
}

export function getLocalizedStringObject(object, key) {
    let content = {};

    locale.getAvailableLangs().forEach(lang => {
        let langCode = str.capitalizeFirstLetter(lang);

        if(lang === locale.reboungLang) {
            langCode = '';
        }

        content[lang] = _.get(object, key + langCode, '');
    });

    return content;
}

export function getLocalizedImageObject(object, key) {
    let content = {};

    locale.getAvailableLangs().forEach(lang => {
        let langCode = str.capitalizeFirstLetter(lang);

        if(lang === locale.reboungLang) {
            langCode = '';
        }

        content[lang] = _.get(object, key + langCode, null);
    });

    return content;
}