import * as popupActions from 'redux/actions/popupActions';

export function getGameFromState(gameId, state) {
    let games = state.gameReducer.games;
    let statuses = state.gameReducer.statuses;
    let game = {};

    for(var i = 0; i < games.length; i++) {
        if(games[i].slug === gameId) {
            game = games[i];
            break;
        }
    }

    return {
        infos : game,
        ...statuses[gameId]
    };
}

export function getPopupDispatch(dispatch) {
    return {
        closePopup: () => dispatch(popupActions.closePopup()),
        openPopup: (content, options = {}) => dispatch(popupActions.openPopup(content, options)),
    };
}