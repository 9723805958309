import React, { Component } from 'react';
import ReactResizeDetector from 'react-resize-detector';

export class ShapedDiv extends Component {
	static defaultProps = {
        // Positions
		topLeft: [0, 0],
        bottomLeft: [0, 100],
		topRight: [100, 0],
        bottomRight: [100, 100],
        unit: '%',
        // Toggle display
        showTop: true,
        showBottom: true,
        showLeft: true,
        showRight: true,
        backgroundColor: null,
        // Animation
        animate: true,
        topLeftTiming: [1.5, 2.5],
        topRightTiming: [1.5, 2.5],
        bottomLeftTiming: [1.5, 2.5],
        bottomRightTiming: [1.5, 2.5],
        topLeftRange: [1, 1],
        topRightRange: [1, 1],
        bottomLeftRange: [1, 1],
        bottomRightRange: [1, 1],
        animationRange: 1,
	};

	constructor(props) {
        super(props);

        this.container = React.createRef();
        this.content = React.createRef();
        this.component = React.createRef();
        this.mounted = false;
        this.tweens = [];

        this.state = {
            containerWidth: 0,
            containerHeight: 0,
            time: (new Date()).getTime(),
        }
    }

    isInViewport() {
        if (!this.component.current) return false;
        const bounding = this.component.current.getBoundingClientRect();

        return (
            (bounding.top >= 0 && bounding.top <= (window.innerHeight || document.documentElement.clientHeight))
            || (bounding.bottom >= 0 && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight))
            || (bounding.top <= 0 && bounding.top >= (window.innerHeight || document.documentElement.clientHeight))
        );
    }
    
    render() {
        return (
            <div className="shaped-div" style={this.createComponentStyle()} ref={this.component}>
                <ReactResizeDetector handleWidth handleHeight onResize={this.onResize.bind(this)} />
                <div className="shaped-div-container" style={this.createContainerStyle()} ref={this.container}>
                    <div className="shaped-div-content" style={this.createContentStyle()} ref={this.content}>
                        {this.props.children}
                    </div>
                    {this.renderTriangles()}
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.mounted = true;

		this.setState({
            containerWidth: this.container.current.offsetWidth,
            containerHeight: this.container.current.offsetHeight,
        });

        if(this.props.animate) {
            this.update();
        }
    }
    
    onResize() {
        this.setState({
            containerWidth: this.container.current.offsetWidth,
            containerHeight: this.container.current.offsetHeight,
        });
    }

	componentWillUnmount() {
        this.mounted = false;
    }

    update() {
        if(!this.mounted) {
            return;
        }

        this.setState({
            time: (new Date()).getTime()
        });
        
        window.requestAnimationFrame(this.update.bind(this));
    };

    getPositions() {
        let positions = {};

        let positionNames = [
            'topLeft',
            'bottomLeft',
            'topRight',
            'bottomRight',
        ];

        for(let i = 0; i < positionNames.length; i++) {
            let positionName = positionNames[i];
            let offsetX = this.props[positionName][0];
            let offsetY = this.props[positionName][1];

            if(this.props.animate && this.isInViewport()) {
                let xTiming = this.props[positionName + 'Timing'][0];
                let yTiming = this.props[positionName + 'Timing'][1];

                if(this.props[positionName + 'Range'][0] > 0) {
                    offsetX = Math.sin(this.state.time / 1000 * xTiming + i * 0.66) * this.props[positionName + 'Range'][0] * this.props.animationRange + this.props[positionName][0];
                    if(positionName === 'topLeft' || positionName === 'bottomLeft') {
                        offsetX = Math.max(offsetX, 0);
                    } else {
                        offsetX = Math.min(offsetX, 100);
                    }
                }

                if(this.props[positionName + 'Range'][1] > 0) {
                    offsetY = Math.cos(this.state.time / 1000 * yTiming + i * 0.66) * this.props[positionName + 'Range'][1] * this.props.animationRange + this.props[positionName][1];
                    if(positionName === 'topLeft' || positionName === 'topRight') {
                        offsetY = Math.max(offsetY, 0);
                    } else {
                        offsetY = Math.min(offsetY, 100);
                    }
                }
            }

            positions[positionName] = [offsetX, offsetY];
        }

        return positions;
    }

    renderTriangles() {
        let {topLeft, bottomLeft, topRight, bottomRight} = this.getPositions();
        let {backgroundColor, unit} = this.props;
        let {containerWidth, containerHeight} = this.state;

        if(!backgroundColor) return null;

        let triangles = [];

        let width = containerWidth + 'px';
        let height = containerHeight + 'px';
        
        // Create top triangle
        if(this.props.showTop) {
            let topDirection = this.props.topRight[1] > this.props.topLeft[1];
            let top = Math.max(topRight[1], topLeft[1]);
            let topSize = (unit === '%' ? containerHeight * top / 100 : top) + 'px';
            let topStyle = {
                width: 0,
                height: 0,
                position: 'absolute',
                top: 0,
                left: 0,
                borderTop: topSize + ' solid ' + backgroundColor,
            };

            topStyle[(topDirection ? 'borderLeft' : 'borderRight')] = width + ' solid transparent';
            
            triangles.push(
                <div style={topStyle} key="top-triangle"></div>
            );
        }
        
        // Create bottom triangle
        if(this.props.showBottom) {
            let bottomDirection = this.props.bottomLeft[1] > this.props.bottomRight[1];
            let bottom = Math.max(100 - bottomRight[1], 100 - bottomLeft[1]);
            let bottomSize = (unit === '%' ? containerHeight * bottom / 100 : bottom) + 'px';
            let bottomStyle = {
                width: 0,
                height: 0,
                position: 'absolute',
                bottom: 0,
                left: 0,
                borderBottom: bottomSize + ' solid ' + backgroundColor,
            };

            bottomStyle[(bottomDirection ? 'borderLeft' : 'borderRight')] = width + ' solid transparent';

            triangles.push(
                <div style={bottomStyle} key="bottom-triangle"></div>
            );
        }

        // Create left triangle
        if(this.props.showLeft) {
            let leftDirection = this.props.topLeft[0] < this.props.bottomLeft[0];
            let left = Math.max(topLeft[0], bottomLeft[0]);
            let leftSize = (unit === '%' ? containerWidth * left / 100 : left) + 'px';
            let leftStyle = {
                width: 0,
                height: 0,
                position: 'absolute',
                left: 0,
                top: 0,
                borderLeft: leftSize + ' solid ' + backgroundColor,
            };

            leftStyle[(leftDirection ? 'borderTop' : 'borderBottom')] = height + ' solid transparent';

            triangles.push(
                <div style={leftStyle} key="left-triangle"></div>
            );
        }
        

        // Create right triangle
        if(this.props.showRight) {
            let rightDirection = this.props.bottomRight[0] < this.props.topRight[0];
            let right = Math.max(100 - topRight[0], 100 - bottomRight[0]);
            let rightSize = (unit === '%' ? containerWidth * right / 100 : right) + 'px';
            let rightStyle = {
                width: 0,
                height: 0,
                position: 'absolute',
                top: 0,
                right: 0,
                borderRight: rightSize + ' solid ' + backgroundColor,
            };

            rightStyle[(rightDirection ? 'borderTop' : 'borderBottom')] = height + ' solid transparent';
            
            triangles.push(
                <div style={rightStyle} key="right-triangle"></div>
            );
        }

        return triangles;
    }

    createContainerStyle() {
        let {topLeft, bottomLeft, topRight, bottomRight} = this.getPositions();
        let {backgroundColor, unit} = this.props;

        let style = {
            width: '100%',
            height: '100%',
        };

        if(backgroundColor) {
            style['position'] = 'relative';
        } else {
            // Clip path
            let clipPath = [];

            clipPath.push(topLeft[0] + unit + ' ' + topLeft[1] + unit);
            clipPath.push(topRight[0] + unit + ' ' + topRight[1] + unit);
            clipPath.push(bottomRight[0] + unit + ' ' + bottomRight[1] + unit);
            clipPath.push(bottomLeft[0] + unit + ' ' + bottomLeft[1] + unit);        

            style['clip-path'] = 'polygon(' + clipPath.join(', ') + ')';
        }

        return style;
    }

    createContentStyle() {
        let style = {
            width: '100%',
            height: '100%',
        };

        return style;
    }

    createComponentStyle() {
        let style = {
            width: '100%',
            height: '100%',
        };

        return style;
    }
}

export default ShapedDiv;