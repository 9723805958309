import React, { Component }  from 'react';

import './Lock.scss';

class Lock extends Component {
    constructor(props) {
        super(props);

        let {step, max, rotationDuration} = props;
        this.innerRing = this.renderInnerRing(step, max);
        this.rotationTransform = 'transform ' + rotationDuration + 's'
    }

	render() {
        return (
            <div className="lock">
                <img src="/images/game/lock/cd-bg.svg" className="base" alt="" />
                {this.renderRing()}
            </div>
        );
    }

    renderRing() {
        let style = {
            transform: 'rotate('+this.props.rotation+'deg)',
            transition: this.rotationTransform
        }

        return (
            <div className="ring" style={style}>
                <img src="/images/game/lock/ring.svg" className="base" alt="" />
                {this.innerRing}
                <div style={{clear: "both"}}></div>
            </div>
        )
    }

    renderInnerRing(step, max) {
        let lines = [];
        let numbers = [];

        for(let i = 0; i < max; i++) {
            let angle = i / max * 360 - 90;
            let isStep = i % step === 0;
            
            // Create line
            let lineStyle = {
                stroke: "rgb(255, 255, 255)"
            };

            lines.push(<line x1={isStep ? "43" : "46"} y1="0" x2="49.5" y2="0" style={lineStyle} transform={"rotate("+angle+")"} key={"line"+i}/>);

            // Create number
            if(isStep) {
                numbers.push(
                    <g transform={"rotate("+angle+") translate(32, 0)"} key={"number-g"+i}>
                        <text transform="rotate(90)" textAnchor="middle" key={"number"+i} fontSize="10">{i}</text>
                    </g>
                )
            }
        }

        return (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <g transform="translate(50, 50)">
                    {lines}
                    {numbers}
                </g>
            </svg>
        );
    }
}

export default Lock;