import React, { Component } from 'react';

export class GameButton extends Component {
	static defaultProps = {
        color: 'blue',
        fontSize: '1.2em',
        style: {},
	};
    
    render() {
        const props = Object.assign({}, this.props);
        delete props.color;
        delete props.className;
        delete props.style;
        
        let className = 'grow-hover f-title';
        className = this.props.className ? className + ' ' + this.props.className : className;

        return (
            <span className={className} style={this.createButtonStyle()} {...props} />
        );
    }

    createButtonStyle() {
        let {color} = this.props;

        let style = {
            'display': 'inline-block',
            'backgroundImage': 'url(\'/images/general/btn_'+color+'.png\')',
            'backgroundSize': '100% 100%',
            'backgroundRepeat': 'no-repeat',
            'fontSize': this.props.fontSize,
            'textTransform': 'uppercase',
            'color': '#ffffff',
            'padding': '0.5em 0.8em 1.2em 0.8em',
            'textShadow': '0.1em 0.1em 0.03em '+color+', -0.04em -0.04em 0 '+color+',  0.04em -0.04em 0 '+color+', -0.04em 0.04em 0 '+color+', 0.04em 0.04em 0 '+color,
            ...this.props.style
        }

        return style;
    }
}

export default GameButton;