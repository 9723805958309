import React, { Component }  from 'react';

import { AnimatedElements } from 'components/general/AnimatedElements';

import './LockControls.scss';

class LockControls extends Component {
    render() {
        return (
            <div className={"lock-controls " + this.props.direction}>
                <AnimatedElements animatedElements={this.props.animatedElements}>
                    <div onClick={this.turnStep.bind(this)} className="control-step">
                        <img src="/images/game/lock/arrow-step-ccw.png" alt="" />
                    </div>
                </AnimatedElements>
                <AnimatedElements animatedElements={this.props.animatedElements}>
                    <div onClick={this.turnFull.bind(this)} className="control-full">
                        <img src="/images/game/lock/arrow-full-ccw.png" alt="" />
                    </div>
                </AnimatedElements>
            </div>
        );
    }

    turnStep(e) {
        e.preventDefault();
        this.props.turnRing(this.props.direction === 'cw' ? 1 : -1);
    }

    turnFull(e) {
        e.preventDefault();
        let stepCount = this.props.max / this.props.step;
        this.props.turnRing(this.props.direction === 'cw' ? stepCount : -stepCount);
    }
}

export default LockControls;