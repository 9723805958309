import React, { Component }  from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { withRouter } from "react-router-dom";

import * as gameActions from 'redux/actions/gameActions';
import { getGameFromState, getPopupDispatch } from 'utils/games/gameUtils';
import { AnimatedElements, animateIn, animateOut } from 'components/general/AnimatedElements';
import * as strapi from 'utils/strapi';
import * as locale from 'utils/locale';

import TransitionLink from 'components/general/TransitionLink';
import ResponsiveElement from 'components/general/ResponsiveElement';
import GameButton from 'components/general/GameButton';
import InfoButton from 'components/game/InfoButton/InfoButton';
import Informations from 'components/general/Informations/Informations';
import Text from 'components/general/Text';

import './Main.scss';
import GoButton from '../../../general/GoButton';
import ExitButton from '../../ExitButton/ExitButton';

const gameSlug = 'casier';

const mapStateToProps = (state, ownProps) => {
    return {
        ...getGameFromState(gameSlug, state),
    }
}

const mapDispatchToProps = dispatch => ({
    updateGameStatus: (status = {}) => dispatch(gameActions.updateGameStatus(gameSlug, status)),
    resetGame: () => dispatch(gameActions.resetGame(gameSlug)),
    closeMenu: (menuItems) => dispatch(gameActions.closeMenu(menuItems)),
    ...getPopupDispatch(dispatch),
});

class Main extends Component {
    constructor(props) {
        super(props);

        this.animatedElements = [];
        this.bg = null;

        this.stepAnimatedElements = [];
        
        this.counter = null;
    }

	render() {
        return (
            <div className="locker-game-main">
                <ResponsiveElement height="90%" width="40%" className="content" top="10%" left="5%">
                    {this.renderContent()}
                </ResponsiveElement>
                <AnimatedElements animatedElements={this.animatedElements} animationClass="locker" animation="slide-down">
                    {this.renderLocker()}
                </AnimatedElements>
                <div className="ui">
                    {/*<MenuButton menuItems={this.getMenuItems()}>
                        <img src="/images/game/general/btn_menu_blue.png" alt="" className="menu-image" />
                    </MenuButton>*/}

                    <ExitButton onTransitionStart={this.onTransitionStart.bind(this)} />

                    {/*<AnimatedElements animatedElements={this.animatedElements} animation="scale" animatedClass="info-button-icon">
                        <InfoButton onClick={(e) => {this.openInformations()}} color="blue" />
                    </AnimatedElements>*/}
                </div>
            </div>
        );
    }

    renderLocker() {
        let { currentLocker, lockers, isStarted } = this.props;
        let locker = lockers[currentLocker];
        let lang = locale.getLang();

        return (
            <ResponsiveElement
                ratio={locker.ratioWidth / locker.ratioHeight}
                top={locker.top}
                height={locker.height}
                left="60%"
                className={"locker"+(isStarted ? ' active' : '')}
            >
                <img src={`/images/game/locker/locker_closed.svg`} alt="Casier" style={{display:(currentLocker === 'closed' ? 'block' : 'none')}} />
                <img src={`/images/game/locker/locker_clean_${lang}.svg`} alt="Casier" style={{display:(currentLocker === 'clean' ? 'block' : 'none')}} />
                <img src={`/images/game/locker/locker_messy_${lang}.svg`} alt="Casier" style={{display:(currentLocker === 'messy' ? 'block' : 'none')}} />
                {this.renderItemClick()}
            </ResponsiveElement>
        );
    }

    renderItemClick() {
        let { items, currentItem, currentLocker, isStarted } = this.props;

        if(!isStarted || currentItem === items.length) {
            return null;
        }

        let item = items[currentItem];

        return (
            <div className="item-click" onClick={this.onItemClick.bind(this)} style={item.clickPosition[currentLocker]}></div>
        )
    }

    onItemClick(e) {
        let { items, currentItem } = this.props;
        
        currentItem++;

        if(currentItem === items.length) {
            this.endRound(true);
        }

        this.props.updateGameStatus({
            currentItem
        });
    }

    componentWillReceiveProps(nextProps) {
        if(
            nextProps.isStarted !== this.props.isStarted
            || nextProps.step !== this.props.step
        ) {
            if(nextProps.isStarted) {
                this.startTimer();
            } else {
                this.stopTimer();
            }
        }
    }

    /**
     * Step 0: Introduction
     * Step 1: Casier clean 1
     * Step 2: Casier clean 2
     * Step 3: Casier clean 3
     * Step 4: Casier messy
     * Step 5: Informations
     */
    renderContent() {
        let { step } = this.props;
        let className = "step-" + step;

        if(step === 0) {
            return (
                <AnimatedElements autoStart animatedElements={this.stepAnimatedElements} animation="slide-right" key={className}  animationClass={className}>
                    <div className={"step " + className}>
                        <p>{strapi.data.getText('locker.introduction')}</p>
                        <div>
                            <div className="go-button grow-hover" onClick={this.nextStep.bind(this)}>
                                <GoButton color="blue" />
                            </div>
                        </div>
                    </div>
                </AnimatedElements>
            );
        }

        return (
            <AnimatedElements autoStart animatedElements={this.stepAnimatedElements} animation="slide-right" key={className} animationClass={className}>
                <div className={"step " + className}>
                    <p>{strapi.data.getText('locker.instructions')}</p>
                    {this.renderItem()}
                    {this.renderDarkItems()}
                    {this.renderTimer()}
                </div>
            </AnimatedElements>
        );
    }

    renderItem() {
        let { items, currentItem } = this.props;
        currentItem = Math.min(items.length - 1, currentItem);

        let item = items[currentItem];
        let icon = item.icon;

        if(icon === 'binder') {
            let lang = locale.getLang();    
            icon += `_${lang}`;
        }

        let imageUrl = "/images/game/locker/" + icon + '.svg';

        return (
            <div className="current-item">
                <div className="text">{locale.textObj(item.name)}</div>
                <img src={imageUrl} alt={item.name} />
            </div>
        );
    }

    renderTimer() {
        let { currentTime, alertTime } = this.props;

        return (
            <div className="timer">
                <div className="text">
                    <Text slug="locker.time"/>
                    <div className={"countdown" + (currentTime >= alertTime ? ' alert' : '')}>
                        <span>{currentTime}</span>
                    </div>
                </div>
            </div>
        );
    }

    renderDarkItems() {
        let { items, currentItem } = this.props;

        let itemsDom = [];

        for(let i = 0; i < items.length; i++) {
            let item = items[i];
            let icon = item.icon;

            if(icon === 'binder') {
                let lang = locale.getLang();    
                icon += `_${lang}`;
            }

            let imageUrl = "/images/game/locker/" + icon + (currentItem <= i ? '_dark' : '') + '.svg';

            itemsDom.push(
                <div className="list-item" key={i}>
                    <img src={imageUrl} alt={item.name} />
                </div>
            );
        }

        return (
            <div className="dark-items">
                {itemsDom}
            </div>
        )
    }

    startTimer() {
        this.counter = setInterval(() => {
            let { currentTime, maxTimes } = this.props;
            const maxTime = maxTimes[this.props.step - 1];

            currentTime++;

            if(currentTime >= maxTime) {
                currentTime = maxTime;
                this.endRound(false);
            }

            this.props.updateGameStatus({
                currentTime: currentTime,
            });
        }, 1000);
    }

    endRound(success) {
        let { step } = this.props;
        this.stopTimer();
        this.props.updateGameStatus({
            currentLocker: 'closed',
        });

        let message = '';
        let action = null
        
        message = strapi.data.getText('locker.end'+step);
        action = () => {
            this.nextStep();
            this.props.closePopup();
        }

        this.props.openPopup(
            <p style={{marginTop: '1em'}}>
                {message}
            </p>, 
            {
                title: success ? strapi.data.getText('game.success') : strapi.data.getText('game.nicetry'),
                responsiveElementOptions: {
                    ratio: 5/3,
                    width: '30%',
                },
                actionButtons: [
                    {
                        content: <GoButton width="11em" />,
                        action: action,
                    }
                ]
            }
        );
    }

    stopTimer() {
        clearInterval(this.counter);
    }

    setupStep(step) {
        let { items } = this.props;

        let stepProps = {
            step: step,
            isStarted: step >= 1 && step <= 4,
        }

        switch(step) {
            case 1:
            case 2:
            case 3:
                stepProps['currentLocker'] = "clean";
                break;
            case 4:
                stepProps['currentLocker'] = "messy";
                break;
            default:
                stepProps['currentLocker'] = "closed";
        }

        if(step === 5) {
            this.openInformations(true);
        }

        if(step >= 1 && step <= 4) {
            stepProps['items'] = _.shuffle(items);

            // TODO : Put the schedule last on the step 4
            if(step === 4) {
                const schedule = _.find(stepProps['items'], {icon: 'schedule'});
                stepProps['items'] = _.without(stepProps['items'], schedule);
                stepProps['items'].push(schedule);
            }

            stepProps['currentTime'] = 0;
            stepProps['currentItem'] = 0;
        }

        return stepProps;
    }

    componentDidMount() {
        animateIn(this.animatedElements);
        //TweenLite.fromTo(this.bg, 0.6, {opacity: 0}, {opacity: 1});
    }

    UNSAFE_componentWillMount() {
        this.props.updateGameStatus(this.setupStep(this.props.step));
    }

    nextStep() {
        animateOut(this.stepAnimatedElements, {
            onComplete: () => {
                this.props.updateGameStatus(this.setupStep(this.props.step + 1));
            }
        });
    }

    resetGame() {
        this.props.resetGame();
        //this.setupStep(this.props.step);
    }

    onTransitionStart(end) {
        clearInterval(this.counter);
        this.props.closeMenu();
        this.props.closePopup();
        //TweenLite.to(this.bg, 0.6, {opacity: 0, delay: 0.5});
        animateOut(this.animatedElements, {
            onComplete: () => {
                end();
            }
        });
    }

    getMenuItems() {
        return [
            <a className="menu-item" href={locale.getUrl('/jeu/:slug', {slug: locale.textObj(this.props.infos.urlSlug)})} key="restart" onClick={(e) => {
                e.preventDefault();
                this.resetGame();
                this.props.closeMenu();
            }}><Text slug="general.restart"/></a>,
            <a className="menu-item" href="/" key="instructions" onClick={(e) => {
                e.preventDefault();
                this.openInformations();
            }}><Text slug="general.informations"/></a>,
            <TransitionLink className="menu-item" to={locale.getUrl("/jeu")} onTransitionStart={this.onTransitionStart.bind(this)} key="return"><Text slug="general.backtomap"/></TransitionLink>,
            // <TransitionLink className="menu-item" to={locale.getUrl("/")} onTransitionStart={this.onTransitionStart.bind(this)} key="return-home"><Text slug="general.backtohome"/></TransitionLink>
        ];
    }

    openInformations(end = false) {
        this.props.closeMenu();

        const actionButtons = end ? [
            {
                type: 'home',
                action: () => {
                    this.onTransitionStart(() => {
                        this.props.history.push(locale.getUrl("/jeu"));
                    });
                }
            },
            {
                type: 'restart',
                action: () => {
                    this.resetGame();
                    this.props.closePopup();
                }
            }
        ] : [
            {
                content: (
                    <GameButton fontSize="2em"><Text slug="general.close"/></GameButton>
                ),
                action: () => {
                    this.props.closePopup();
                }
            }
        ];

        this.props.openPopup(
            <Informations gameSlug="casier" />
            , {
                title: locale.textObj(this.props.infos.name),
                responsiveElementOptions: {
                    fontSizeRef: 'width',
                    ratio: 1.1,
                    width: '80%',
                },
                autoAdjust: false,
                actionButtons: actionButtons
            }
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));