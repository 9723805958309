export default (state = {}, action) => {
    switch (action.type) {
        case 'SET_URLS':
            return setUrls(state, action);
        default:
            return state
    }
}

function setUrls(state = {}, action) {
    let newState = {
        ...state,
        urls: action.payload,
    };

    return newState;
}