import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as _ from 'lodash';
import * as locale from 'utils/locale';

import ScrollingDiv from 'components/general/ScrollingDiv/ScrollingDiv';

import './Informations.scss';

const mapStateToProps = (state, ownProps) => {
	return {
		...state.tipsReducer
	}
}

class Informations extends Component {
	static defaultProps = {
		slides : [],
		gameSlug : null,
		scroll: true,
	};

	render() {
		return (
			<div className="informations-component">
				{this.renderSlides()}
			</div>
		)
	}

	renderSlides() {
		let { slides, gameSlug } = this.props;

		if(gameSlug) {
			slides = this.getContentWithSlug(gameSlug);
		}

		slides =
			slides.length === 0 ?
				[this.formatSlide(<div className="slide">{this.props.children}</div>)] :
				_.map(slides, (html, i) => this.formatSlide(<div className="slide" dangerouslySetInnerHTML={{__html:html}} key={i}  style={{width: 100 / slides.length + '%'}}/>));

		return (
			<div className="slides" style={{height: "100%", width: slides.length * 100 + '%'}}>
				{slides}
			</div>
		);
	}

	formatSlide(content) {
		if(!this.props.scroll) {
			return content;
		}

		return (
			<ScrollingDiv key="key">
				{content}
			</ScrollingDiv>
		);
	}

	getContentWithSlug(gameSlug) {
		for(let i = 0; i < this.props.questions.length; i++) {
			let question = this.props.questions[i];

			if(question.gameSlug === gameSlug) {
				return [question.answer.map(answer => answer[locale.getLang()]).join('')];
			}
		}

		return null;
	}
}

export default connect(mapStateToProps)(Informations);