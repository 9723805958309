import React, { Component }  from 'react';
import { connect } from 'react-redux';

import { getGameFromState, getPopupDispatch } from 'utils/games/gameUtils';
import * as gameActions from 'redux/actions/gameActions';

import Main from './Main/Main';

import './LockGame.scss';

const gameSlug = 'cadenas';

const mapStateToProps = (state, ownProps) => {
    return {
        ...getGameFromState(gameSlug, state),
    }
}

const mapDispatchToProps = dispatch => ({
    resetGame: (status = {}) => dispatch(gameActions.resetGame(gameSlug, status)),
    ...getPopupDispatch(dispatch),
});

class LockGame extends Component {
	render() {
        return (
            <div className="lock-game">
                {this.renderGame()}
            </div>
        );
    }

    UNSAFE_componentWillMount() {
        this.props.resetGame();
    }

    renderGame() {
        switch(this.props.screen) {
            case 0 :
                return <Main />
            default:
                return null;
        }
    }

    resetGame(e) {
        if(e) {
            e.preventDefault();
        }
        this.props.resetGame({
            screen : 1
        });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LockGame);