export default (state = {}, action) => {
    switch (action.type) {
        case 'UPDATE_RESOLUTION':
            return updateResolution(state, action);
        case 'OPEN_GAME':
            return openGame(state, action);
        case 'RESET_GAME':
            return resetGame(state, action);
        case 'UPDATE_GAME_STATUS':
            return updateGameStatus(state, action);
        case 'OPEN_MENU':
            return openMenu(state, action);
        case 'CLOSE_MENU':
            return closeMenu(state, action);
        default:
            return state
    }
}

function openGame(state = {}, action) {
    let { gameId } = action.payload;

    let newState = {
        ...state
    };

    const completion = {...state['completion']};

    completion.games.push(gameId);

    newState.completion = completion;

    // Save in the local storage
    window.localStorage.setItem('completion', JSON.stringify(newState.completion));

    return newState;
}

function resetGame(state = {}, action) {
    let { gameSlug, status } = action.payload;
    let defaultStatus = JSON.parse(JSON.stringify(state.defaultStatuses[gameSlug]));

    let newState = {
        ...state
    };

    newState['statuses'][gameSlug] = {
        ...defaultStatus,
        ...status
    };

    return newState;
}

function updateGameStatus(state = {}, action) {
    let { gameSlug, status } = action.payload;
    let gameStatus = state['statuses'][gameSlug];

    let newState = {
        ...state
    };

    newState['statuses'][gameSlug] = {
        ...gameStatus,
        ...status
    };

    return newState;
}

function openMenu(state = {}, action) {
    let { menuItems } = action.payload;

    let newState = {
        ...state,
        menu : {
            opened : true,
            menuItems : menuItems
        }
    };

    return newState;
}

function closeMenu(state = {}, action) {
    let newState = {
        ...state,
        menu : {
            opened : false,
            menuItems : state.menu.menuItems
        }
    };

    return newState;
}

function updateResolution(state = {}, action) {
    let w = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName('body')[0],
        x = w.innerWidth || e.clientWidth || g.clientWidth,
        y = w.innerHeight|| e.clientHeight|| g.clientHeight;

    let resolution = {x:x, y:y};

    let newState = {
        ...state,
        resolution : resolution
    };

    return newState;
}