import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import configureStore from 'redux/store';
//import * as serviceWorker from 'serviceWorker';
import * as _ from 'lodash';
import * as strapi from 'utils/strapi';

import App from 'components/App';

import defaultPopup from 'data/defaultPopup.json';
import defaultGame from 'data/defaultGame.json';
import defaultLockGameState from 'data/games/defaultLockGameState.json';
import defaultExtracurricularGameState from 'data/games/defaultExtracurricularGameState.json';
import defaultProfessionalsGameState from 'data/games/defaultProfessionalsGameState.json';
import defaultLockerGameState from 'data/games/defaultLockerGameState.json';
import defaultAgendaGameState from 'data/games/defaultAgendaGameState.json';
import defaultDeplacementsGameState from 'data/games/defaultDeplacementsGameState.json';

import 'assets/scss/index.scss';

const gameStatuses = {
    "cadenas" : defaultLockGameState,
    "parascolaires" : defaultExtracurricularGameState,
    "personnes-ressources" : defaultProfessionalsGameState,
    "casier" : defaultLockerGameState,
    "agenda" : defaultAgendaGameState,
    "en-route-vers-le-secondaire" : defaultDeplacementsGameState,
    "vie-sociale" : {},
    "programmes" : {},
    "entree-au-secondaire" : {},
    "covid-19" : {},
};

const replaceText = (value) => {
    if(value.startsWith("txt:")) {
        let slug = value.substr(4);
        return strapi.data.getText(slug);
    } else {
        return value;
    }
};

const updateTexts = (obj) => {
    for (let property in obj) {
        if (obj.hasOwnProperty(property)) {
            if (typeof obj[property] === "object") {
                updateTexts(obj[property]);
            } else if(Array.isArray(obj[property])) {
                for(let i = 0; i < obj[property].length; i++) {
                    obj[property][i] = replaceText(obj[property][i]);
                }
            } else if(typeof obj[property] === "string") {
                obj[property] = replaceText(obj[property]);
            }
        }
    }

    return obj;
};

var images = [];
const preload = (imageUrls) => {
    for(let i = 0; i < imageUrls.length; i++) {
        images[i] = new Image();
        images[i].src = imageUrls[i];
        images[i].onload = (e) => {
            //console.log('Image preloaded : ' + e.target.src);
        }
        //console.log('Preloading : ' + imageUrls[i]);
    }
}

preload([
    '/images/general/btn_blue.png',
    '/images/general/list-item.png',
    '/images/general/quote.png',
    '/images/logos/go-secondaire_logo_bleu_fr.svg',
    '/images/logos/go-secondaire_logo_bleu_en.svg',
    '/images/logos/go-secondaire_logo_jaune.svg',
    '/images/popup/popup_bg.png',
    '/images/popup/popup_bg.svg',
    '/images/popup/popup_btn_restart.png',
    '/images/popup/popup_title.png',
    '/images/game/static/background.jpg',
    '/images/game/professionals/background.jpg',
    '/images/game/professionals/instruction_bg.png',
    '/images/game/professionals/intro_left.png',
    '/images/game/professionals/intro_popup_bg.svg',
    '/images/game/professionals/intro_right.png',
    '/images/game/professionals/name_bg.png',
    '/images/game/professionals/person_0.png',
    '/images/game/professionals/person_1.png',
    '/images/game/professionals/person_2.png',
    '/images/game/professionals/person_3.png',
    '/images/game/professionals/person_4.png',
    '/images/game/professionals/person_5.png',
    '/images/game/professionals/person_6.png',
    '/images/game/map/map_icon.png',
    '/images/game/map/map_main.png',
    '/images/game/locker/agenda.svg',
    '/images/game/locker/binder.svg',
    '/images/game/locker/book.svg',
    '/images/game/locker/lunch.svg',
    '/images/game/locker/schedule.svg',
    '/images/game/locker/shirt.svg',
    '/images/game/locker/tablet.svg',
    '/images/game/locker/agenda_dark.svg',
    '/images/game/locker/binder_dark.svg',
    '/images/game/locker/book_dark.svg',
    '/images/game/locker/lunch_dark.svg',
    '/images/game/locker/schedule_dark.svg',
    '/images/game/locker/shirt_dark.svg',
    '/images/game/locker/tablet_dark.svg',
    '/images/game/locker/background.jpg',
    '/images/game/locker/countdown_bg.png',
    '/images/game/locker/item_bg.png',
    '/images/game/locker/locker_clean.svg',
    '/images/game/locker/locker_closed.svg',
    '/images/game/locker/locker_messy.svg',
    '/images/game/locker/time_bg.png',
    '/images/game/lock/arrow-full-ccw.png',
    '/images/game/lock/arrow-step-ccw.png',
    '/images/game/lock/background.svg',
    '/images/game/lock/cd-bg.svg',
    '/images/game/lock/header_number_bg.png',
    '/images/game/lock/instructions_bg.png',
    '/images/game/lock/level_display_title.png',
    '/images/game/lock/numbers_bg.png',
    '/images/game/lock/opened_lock.png',
    '/images/game/lock/ring.svg',
    '/images/game/lock/skip_instruction.png',
    '/images/game/lock/step_number_bg.png',
    '/images/game/lock/step_number_completed.png',
    '/images/game/extracurricular/background.jpg',
    '/images/game/extracurricular/instructions_bg.png',
    '/images/game/extracurricular/popup_title.png',
    '/images/game/extracurricular/school_bg.png',
    '/images/game/agenda/answer_bg.png',
    '/images/game/agenda/instructions_bg.png',
]);

// Get texts
fetch(process.env.REACT_APP_GRAPHQL_URL, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
    body: JSON.stringify({query: `{ 
        texts {key, content, contentEn},
        games {name, hidden, nameEn, mapPositionX, mapPositionY, slug, slugEn, id, description, descriptionEn, icon {url}, icon2 {url}, icon3 {url}},
        schools {name, id, website},
        tips {id, question, questionEn, hasInteractive, game {id, slug, slugEn}, answer {content, contentEn, isSimpleContent, imagePosition, textAlign, image {url}, imageEn {url}}},
        partners {name, type, url, class, image {url}},
        extracurriculars {name, nameEn, subtitle, subtitleEn, description, descriptionEn, showInAll, icon {url}},
        professionals {id, name, description, nameEn, descriptionEn, availableFr, availableEn},
        professionalquestions {nameBox, description, question, nameBoxEn, descriptionEn, questionEn, availableFr, availableEn, answer {id}, otherAnswers {id}},
        agendastyles {id, slug, programmaticImage, imageLayer, questionTitle, questionTitleEn, questionText, questionTextEn, answer1Title, answer1TitleEn, answer2Title, answer2TitleEn, conclusionTitle, conclusionTitleEn, conclusionText, conclusionTextEn, answer1Image {url}, answer1ImageEn {url}, answer2Image {url}, answer2ImageEn {url}},
        deplacementquestions {id, isTrueFalse, canBeFirst, answerTrue, answerTrueEn, answerFalse, answerFalseEn, titleTrue, titleTrueEn, titleFalse, titleFalseEn, question, questionEn},
    }`})
})
.then(r => r.json())
.then(resp => {
    //console.log(resp.data);
    strapi.data.init(resp.data);
    window.strapi = strapi;

    gameStatuses['parascolaires']['activities'] = formatExtracurriculars(resp.data.extracurriculars);
    gameStatuses['personnes-ressources']['professionals'] = formatProfessionals(resp.data.professionals);
    gameStatuses['personnes-ressources']['questions'] = formatProfessionalQuestions(resp.data.professionalquestions);
    gameStatuses['agenda']['questions'] = formatAgendaQuestions(resp.data.agendastyles);
    gameStatuses['en-route-vers-le-secondaire']['questions'] = formatDeplacementQuestions(resp.data.deplacementquestions);

    let defaultStore = {
        schoolsReducer: {
            schools : formatSchools(resp.data.schools)
        },
        tipsReducer: {
            questions : formatTips(resp.data.tips)
        },
        partnersReducer: formatPartners(resp.data.partners),
        popupReducer: {
            ...defaultPopup,
            defaultStatus: JSON.parse(JSON.stringify(defaultPopup)),
        },
        gameReducer: {
            ...defaultGame,
            games: formatGames(resp.data.games),
            completion: getCompletion(),
            statuses : gameStatuses,
            defaultStatuses : JSON.parse(JSON.stringify(gameStatuses)),
        },
        siteReducer: {
            urls: {},
        }
    };
    
    ReactDOM.render(
        <Provider store={configureStore(updateTexts(defaultStore))}>
            <App />
        </Provider>,
        document.getElementById('root')
    );
});



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

function getCompletion() {
    const completion = window.localStorage.getItem('completion');
    const emptyCompletion = {
        games: [],
    };

    if(completion !== null) {
        try {
            const response = JSON.parse(completion);
            return {...emptyCompletion, ...response};
        } catch (e) {
            return emptyCompletion;
        }
    }

    return emptyCompletion;
}

function formatTips(tips) {
    let formattedTips = [];

    tips.forEach((tip, i) => {
        let formattedTip = {
            gameId: _.get(tip.game, 'id', tip.question),
            gameSlug: _.get(tip.game, 'slug', tip.question),
            urlSlug: strapi.getLocalizedStringObject(tip.game, 'slug'),
            question: strapi.getLocalizedStringObject(tip, 'question'),
            hasInteractive: tip.hasInteractive,
            answer: tip.answer.map(slide => strapi.slideToHtml(slide)),
        };

        // Hardcode the order
        if(parseFloat(tip.id) === 9 || parseFloat(tip.id) === 11) {
            formattedTips.unshift(formattedTip);
        } else {
            formattedTips.push(formattedTip);
        }
    });
    
    return formattedTips;
}

function formatPartners(partners) {
    let formattedPartners = {
        partners : [],
        financialPartners : [],
        tableMembers : [],
    };

    partners.forEach((partner, i) => {
        formattedPartners[partner.type].push({
            name: partner.name,
            class: partner.class,
            url: partner.url,
            image: partner.image ? strapi.getImageUrl(partner.image.url) : null,
        });
    });

    return formattedPartners;
}

function formatSchools(schools) {
    let formattedSchools = {};

    schools.forEach((school, i) => {
        formattedSchools[school.id] = school;
    });

    return formattedSchools;
}

function formatGames(games) {    
    return games
        .filter(game => !game.hidden)
        .map(game => {
            let obj = {
                ...game,
                urlSlug: strapi.getLocalizedStringObject(game, 'slug'),
                name: strapi.getLocalizedStringObject(game, 'name'),
                description: strapi.getLocalizedStringObject(game, 'description'),
                icon: strapi.getImageUrl(game.icon.url),
                icon2: strapi.getImageUrl(game.icon2.url),
                icon3: strapi.getImageUrl(game.icon3.url),
            }
            return obj;
        });
}

function formatExtracurriculars(extracurriculars) {    
    return extracurriculars.map(extracurricular => {
        return {
            name: strapi.getLocalizedStringObject(extracurricular, 'name'),
            subtitle: strapi.getLocalizedStringObject(extracurricular, 'subtitle'),
            description: strapi.getLocalizedStringObject(extracurricular, 'description'),
            showInAll: extracurricular.showInAll,
            icon: strapi.getImageUrl(extracurricular.icon.url),
            categories: [],
        }
    });
}

function formatProfessionals(professionals) {
    let formattedProfessionals = {};

    professionals.forEach((professional, i) => {
        formattedProfessionals[professional.id] = {
            name: strapi.getLocalizedStringObject(professional, 'name'),
            description: strapi.getLocalizedStringObject(professional, 'description'),
            availableFr: professional.availableFr,
            availableEn: professional.availableEn,
            //description: strapi.mdToHtml(professional.description),
        };
    });

    return formattedProfessionals;
}

function formatProfessionalQuestions(professionalQuestions) {    
    return professionalQuestions.map(professionalQuestion => {
        return {
            nameBox: strapi.getLocalizedStringObject(professionalQuestion, 'nameBox'),
            description: strapi.getLocalizedStringObject(professionalQuestion, 'description'),
            question: strapi.getLocalizedStringObject(professionalQuestion, 'question'),
            answer: professionalQuestion.answer.id,
            availableFr: professionalQuestion.availableFr,
            availableEn: professionalQuestion.availableEn,
            otherAnswers: professionalQuestion.otherAnswers.map(otherAnswer => otherAnswer.id),
        }
    });
}

function formatAgendaQuestions(agendaQuestions) {
    return agendaQuestions.map(agendaQuestion => {
        return {
            programmaticImage: agendaQuestion.programmaticImage,
            imageLayer: agendaQuestion.imageLayer,
            slug: agendaQuestion.slug,
            questionTitle: strapi.getLocalizedStringObject(agendaQuestion, 'questionTitle'),
            questionText: strapi.getLocalizedStringObject(agendaQuestion, 'questionText'),
            conclusionTitle: strapi.getLocalizedStringObject(agendaQuestion, 'conclusionTitle'),
            conclusionText: strapi.getLocalizedStringObject(agendaQuestion, 'conclusionText'),
            answers: [
                {
                    id: 0,
                    text: strapi.getLocalizedStringObject(agendaQuestion, 'answer1Title'),
                    image: {
                        fr: strapi.getImageUrl(_.get(agendaQuestion.answer1Image, 'url')),
                        en: strapi.getImageUrl(_.get(agendaQuestion.answer1ImageEn, 'url')),
                    },
                },
                {
                    id: 1,
                    text: strapi.getLocalizedStringObject(agendaQuestion, 'answer2Title'),
                    image: {
                        fr: strapi.getImageUrl(_.get(agendaQuestion.answer2Image, 'url')),
                        en: strapi.getImageUrl(_.get(agendaQuestion.answer2ImageEn, 'url')),
                    },
                },
            ],
        }
    });
}

function formatDeplacementQuestions(deplacementQuestions) {
    return deplacementQuestions.map(deplacementQuestion => {
        return {
            canBeFirst: deplacementQuestion.canBeFirst,
            isTrueFalse: deplacementQuestion.isTrueFalse,
            question: strapi.getLocalizedStringObject(deplacementQuestion, 'question'),
            answers: {
                true: strapi.getLocalizedStringObject(deplacementQuestion, 'answerTrue'),
                false: strapi.getLocalizedStringObject(deplacementQuestion, 'answerFalse'),
            },
            titles: {
                true: strapi.getLocalizedStringObject(deplacementQuestion, 'titleTrue'),
                false: strapi.getLocalizedStringObject(deplacementQuestion, 'titleFalse'),
            },
        }
    });
}
