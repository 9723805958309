import React, { Component }  from 'react';
import { connect } from 'react-redux';
import * as gameActions from 'redux/actions/gameActions';
import { withRouter } from "react-router-dom";

import { getGameFromState, getPopupDispatch } from 'utils/games/gameUtils';
import { animateIn, animateOut } from 'components/general/AnimatedElements';
import * as locale from 'utils/locale';
import * as strapi from 'utils/strapi';

import GoButton from 'components/general/GoButton';

import './Main.scss';
import AnswerHandle from './AnswerHandle/AnswerHandle';
import ExitButton from '../../ExitButton/ExitButton';

const gameSlug = 'en-route-vers-le-secondaire';

const mapStateToProps = (state, ownProps) => {
    return {
        ...getGameFromState(gameSlug, state),
    }
}

const mapDispatchToProps = dispatch => ({
    updateGameStatus: (status = {}) => dispatch(gameActions.updateGameStatus(gameSlug, status)),
    closeMenu: (menuItems) => dispatch(gameActions.closeMenu(menuItems)),
    resetGame: (status = {}) => dispatch(gameActions.resetGame(gameSlug, status)),
    ...getPopupDispatch(dispatch),
});

class Main extends Component {
    constructor(props) {
        super(props);

        this.animatedElements = [];
        this.bg = null;
    }

    /**
     * COMPONENT LIFECYCLE
     */
    componentDidMount() {
        animateIn(this.animatedElements);
    }

    UNSAFE_componentWillMount() {
        this.prepareQuestions();
    }

    /**
     * TRANSITION
     */
    onTransitionStart(end) {
        this.props.closeMenu();
        this.props.closePopup();
        animateOut(this.animatedElements, {
            onComplete: () => {
                end();
            }
        });
    }

    /**
     * GAME LOGIC
     */
    resetGame() {
        this.props.resetGame();
        this.prepareQuestions();
    }

    prepareQuestions() {
        // Randomize questions
        const questions = this.props.questions.sort((a, b) => 0.5 - Math.random());

        // Make the questions with the attribute "canBeFirst = false" doesn't appear first
        for(let i = 0; i < questions.length; i++) {
            if(!questions[i].canBeFirst) {
                // Find a random index that is not the current one
                const question = questions.splice(i, 1)[0];
                questions.push(question);
            }
        }
        
        this.props.updateGameStatus({
            questions : questions,
        });
    }

    getCurrentQuestion() {
        return this.props.questions[this.props.currentQuestion];
    }

    sendAnswer(answer) {
        const question = this.getCurrentQuestion();
        const answerKey = answer ? 'true' : 'false';

        // Show conclusion
        const html = strapi.mdToHtml(locale.textObj(question.answers[answerKey]));

        this.props.openPopup(
            <div style={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'left',
                height: '100%',
            }} dangerouslySetInnerHTML={{__html: html}} />, 
            {
                title: locale.textObj(question.titles[answerKey]),
                responsiveElementOptions: {
                    width: '40%',
                },
                actionButtons: [
                    {
                        content: <GoButton color="blue" width="10em" onClick={this.nextQuestion.bind(this)} />,
                    },
                ]
            }
        );
    }

    nextQuestion() {
        const completed = this.props.currentQuestion === this.props.numberOfQuestions - 1;

        this.props.updateGameStatus({
            currentQuestion: this.props.currentQuestion + (!completed ? 1 : 0),
        });

        this.props.closePopup();

        if(completed) {
            this.endGame();
        }
    }

    endGame() {
        // Show conclusion
        const html = strapi.mdToHtml(strapi.data.getText('deplacements.end'));

        this.props.openPopup(
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
            }} dangerouslySetInnerHTML={{__html: html}} />, 
            {
                responsiveElementOptions: {
                    width: '30%',
                    height: '40%',
                },
                actionButtons: [
                    {
                        type: 'home',
                        action: () => {
                            this.onTransitionStart(() => {
                                this.props.history.push(locale.getUrl("/jeu"));
                            });
                        }
                    },
                    {
                        type: 'restart',
                        action: () => {
                            this.resetGame();
                            this.props.closePopup();
                        }
                    },
                ]
            }
        );
    }

    /**
     * RENDER
     */

    renderQuestion() {        
        return (
            <div className="question">
                <div className="title">
                    Question #{ this.props.currentQuestion + 1 }
                </div>
                <div className="text">
                    { locale.textObj(this.getCurrentQuestion().question) }
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="deplacements-game-main">
                {this.renderQuestion()}
                <AnswerHandle
                    draggable={false}
                    clickable={true}
                    sendAnswer={this.sendAnswer.bind(this)}
                    isTrueFalse={this.getCurrentQuestion().isTrueFalse}
                />
                <div className="ui">
                    <ExitButton onTransitionStart={this.onTransitionStart.bind(this)} />
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));