import * as _ from "lodash";

import * as str from 'utils/str';

import domains from 'data/domains.json';
import urls from 'data/urls.json';

let availableLang = Object.keys(urls);
let lang = getDomainAttr('lang');
let env = getDomainAttr('env');

export function getDomainAttr(attr, dfl = null) {
    let domain = window.location.hostname;
    return _.get(domains[domain], attr, dfl);
}

export function getUrl(key, params = {}, absolute = false, forcedLang = null) {
    let usedLang = forcedLang|| lang;
    let url = _.get(urls[usedLang], key);

    for(let key in params) {
        url = url.replace(':'+key, params[key]);
    }

    if(absolute) {
        url = "//" + getBaseUrl(usedLang) + url;
    }

    return url;
}

export function getBaseUrl(forcedLang = null) {
    let usedLang = forcedLang|| lang;

    for(let domain in domains) {
        if(domains[domain].env === env && domains[domain].lang === usedLang) {
            if(env === 'dev') {
                domain += ':3000';
            }

            return domain;
        }
    }

    return '';
}

export function getLang() {
    return lang;
};

export function setLang(l) {
    if(availableLang.indexOf(l) !== -1) {
        lang = l;
    }
}

export function getAvailableLangs() {
    return availableLang;
}

export const reboungLang = 'fr';

export function langSuffix(s, l) {
    let langCode = str.capitalizeFirstLetter(l);
    return s + langCode;
}

export function textObj(textObj, defaultValue = null) {
    let text = _.get(textObj, lang);

    if(!text && lang !== reboungLang) {
        return text || _.get(textObj, reboungLang, defaultValue);
    }

    return text || defaultValue;
}

export function getSwitcherLanguages() {
    let otherLanguages = getAvailableLangs().filter((lan) => {
        return lan !== lang;
    });

    return otherLanguages;
}

export function getUrls(url, params = {}) {
    let urls = {};

    availableLang.forEach(lang => {
        let langParams = _.get(params, lang, {});
        urls[lang] = getUrl(url, langParams, true, lang);
    });

    return urls;
}