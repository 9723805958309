import React, { Component }  from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import * as gameActions from 'redux/actions/gameActions';
import { getGameFromState, getPopupDispatch } from 'utils/games/gameUtils';
import { AnimatedElements, animateIn, animateOut } from 'components/general/AnimatedElements';
import * as locale from 'utils/locale';

import TransitionLink from 'components/general/TransitionLink';
import ResponsiveElement from 'components/general/ResponsiveElement';
import GameButton from 'components/general/GameButton';
import Text from 'components/general/Text';

import './Intro.scss';
import ExitButton from '../../ExitButton/ExitButton';

const gameSlug = 'personnes-ressources';

const mapStateToProps = (state, ownProps) => {
    return {
        ...getGameFromState(gameSlug, state),
    }
}

const mapDispatchToProps = dispatch => ({
    updateGameStatus: (status = {}) => dispatch(gameActions.updateGameStatus(gameSlug, status)),
    closeMenu: (menuItems) => dispatch(gameActions.closeMenu(menuItems)),
    ...getPopupDispatch(dispatch),
});

class Intro extends Component {
    constructor(props) {
        super(props);

        this.animatedElements = [];
    }

	render() {
        return (
            <div className="professionals-game-intro">
                <AnimatedElements animatedElements={this.animatedElements} animatedClass="person" animation="slide-up">
                    <ResponsiveElement className="person-container" ratio={717/935} height="85%" left="0" bottom="0%">
                        <img src="/images/game/professionals/intro_left.png" className="person" alt="dame" />
                    </ResponsiveElement>
                </AnimatedElements>

                <AnimatedElements animatedElements={this.animatedElements} animatedClass="person" animation="slide-up">
                    <ResponsiveElement className="person-container" ratio={1596/1730} height="85%" right="0" bottom="0%">
                        <img src="/images/game/professionals/intro_right.png" className="person" alt="dame" />
                    </ResponsiveElement>
                </AnimatedElements>

                <div className="ui">
                    {/*<MenuButton menuItems={this.getMenuItems()}>
                        <img src="/images/game/general/btn_menu_white.png" alt="" className="menu-image" />
                    </MenuButton>*/}
                    <ExitButton onTransitionStart={this.onTransitionStart.bind(this)} />
                </div>
            </div>
        );
    }

    componentDidMount() {
        animateIn(this.animatedElements);

        this.props.openPopup(
            <div className="professionals-intro-popup-content">
                <div className="texts">
                    <h2><Text slug="general.instructions"/></h2>
                    <h3>1</h3>
                    <p><Text slug="professionals.instructions.1"/></p>
                    <h3>2</h3>
                    <p><Text slug="professionals.instructions.2"/></p>
                    <GameButton className="next-button" onClick={this.nextStep.bind(this)}>
                        <Text slug="general.start"/>
                    </GameButton>
                </div>
            </div>, 
            {
                overlayOpacity: 0,
                clickBlock: false,
                responsiveElementOptions: {
                    ratio: 875/752,
                    width: '50%',
                    maxWidth: '1047px',
                    fontSizeRef: 'self',
                }
            }
        );
    }

    UNSAFE_componentWillMount() {
        
    }

    nextStep(e) {
        e.preventDefault();
        this.onTransitionStart(() => {
            this.props.updateGameStatus({
                screen: 1
            });
        }, true);
    }

    onTransitionStart(end) {
        this.props.closePopup();
        this.props.closeMenu();

        animateOut(this.animatedElements, {
            onComplete: () => {
                end();
            }
        });
    }

    getMenuItems() {
        return [
            <TransitionLink className="menu-item" to={locale.getUrl("/jeu")} onTransitionStart={this.onTransitionStart.bind(this)} key="return"><Text slug="general.backtomap"/></TransitionLink>,
            // <TransitionLink className="menu-item" to={locale.getUrl("/")} onTransitionStart={this.onTransitionStart.bind(this)} key="return-home"><Text slug="general.backtohome"/></TransitionLink>
        ];
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Intro));