import React, { Component }  from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import * as gameActions from 'redux/actions/gameActions';
import { getGameFromState, getPopupDispatch } from 'utils/games/gameUtils';
import { animateIn, animateOut } from 'components/general/AnimatedElements';
import * as locale from 'utils/locale';

import TransitionLink from 'components/general/TransitionLink';
import GameButton from 'components/general/GameButton';
import Informations from 'components/general/Informations/Informations';
import Text from 'components/general/Text';

import './Main.scss';
import ExitButton from '../../ExitButton/ExitButton';

const gameSlug = 'programmes';

const mapStateToProps = (state, ownProps) => {
    return {
        ...getGameFromState(gameSlug, state),
    }
}

const mapDispatchToProps = dispatch => ({
    updateGameStatus: (status = {}) => dispatch(gameActions.updateGameStatus(gameSlug, status)),
    closeMenu: (menuItems) => dispatch(gameActions.closeMenu(menuItems)),
    ...getPopupDispatch(dispatch),
});

class Main extends Component {
    constructor(props) {
        super(props);

        this.animatedElements = [];
        this.bg = null;
    }

	render() {
        return (
            <div className="programmes-game-main">
                <div className="ui">
                    {/*<MenuButton menuItems={this.getMenuItems()}>
                        <img src="/images/game/general/btn_menu_blue.png" alt="" className="menu-image" />
                    </MenuButton>*/}
                    <ExitButton onTransitionStart={this.onTransitionStart.bind(this)} />
                </div>
            </div>
        );
    }

    componentDidMount() {
        animateIn(this.animatedElements);
        this.props.openPopup(
            <Informations gameSlug={gameSlug} />
            , {
                title: locale.textObj(this.props.infos.name),
                clickBlock: false,
                overlayOpacity: 0,
                responsiveElementOptions: {
                    ratio: null,
                    width: '80%',
                    height: '90%',
                    maxWidth: null,
                    maxHeight: null,
                },
                autoAdjust: false,
                actionButtons: [
                    {
                        content: (
                            <TransitionLink className="map-button" to={locale.getUrl("/jeu")} onTransitionStart={this.onTransitionStart.bind(this)} key="return">
                                <GameButton><Text slug="general.backtomap"/></GameButton>
                            </TransitionLink>
                        ),
                    }
                ]
            }
        )
    }

    UNSAFE_componentWillMount() {
        
    }

    resetGame() {
        this.props.resetGame();
    }


    onTransitionStart(end) {
        this.props.closeMenu();
        this.props.closePopup();
        animateOut(this.animatedElements, {
            onComplete: () => {
                end();
            }
        });
    }

    getMenuItems() {
        return [
            <TransitionLink className="menu-item" to={locale.getUrl("/jeu")} onTransitionStart={this.onTransitionStart.bind(this)} key="return"><Text slug="general.backtomap"/></TransitionLink>,
            // <TransitionLink className="menu-item" to={locale.getUrl("/")} onTransitionStart={this.onTransitionStart.bind(this)} key="return-home"><Text slug="general.backtohome"/></TransitionLink>
        ];
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));