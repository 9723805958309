export const closePopup = () => dispatch => {
    dispatch({
        type: 'CLOSE_POPUP',
        payload: {}
    })
}

export const openPopup = (content, options) => dispatch => {
    dispatch({
        type: 'OPEN_POPUP',
        payload: {
            content : content,
            options : options,
        }
    })
}