import React, { Component }  from 'react';
import { connect } from 'react-redux';
import { TweenLite } from "gsap/TweenMax";
import { withRouter } from "react-router-dom";

import * as gameActions from 'redux/actions/gameActions';
import { getGameFromState, getPopupDispatch } from 'utils/games/gameUtils';
import { AnimatedElements, animateIn, animateOut } from 'components/general/AnimatedElements';
import * as strapi from 'utils/strapi';
import * as locale from 'utils/locale';

import InfoButton from 'components/game/InfoButton/InfoButton';
import TransitionLink from 'components/general/TransitionLink';
import ResponsiveElement from 'components/general/ResponsiveElement';
import Text from 'components/general/Text';

import Lock from './Lock/Lock';
import Instructions from './Instructions/Instructions';
import LockControls from './LockControls/LockControls';
import CodeDisplay from './CodeDisplay/CodeDisplay';
import LevelDisplay from './LevelDisplay/LevelDisplay';

import './Main.scss';
import GoButton from '../../../general/GoButton';
import ExitButton from '../../ExitButton/ExitButton';

const gameSlug = 'cadenas';

const mapStateToProps = (state, ownProps) => {
    return {
        ...getGameFromState(gameSlug, state),
    }
}

const mapDispatchToProps = dispatch => ({
    updateGameStatus: (status = {}) => dispatch(gameActions.updateGameStatus(gameSlug, status)),
    resetGame: (status = {}) => dispatch(gameActions.resetGame(gameSlug, status)),
    closeMenu: (menuItems) => dispatch(gameActions.closeMenu(menuItems)),
    ...getPopupDispatch(dispatch),
});

class Main extends Component {
    constructor(props) {
        super(props);

        this.isRotating = false;
        this.animatedElements = [];
        this.bg = null;
        this.lock = React.createRef();
    }

	render() {
        let { max, step, rotationDuration } = this.props.settings;
        let stepCount = max / step;
        let stepDegres = 360 / stepCount;
        let {combination, currentNumber, levels, currentLevel} = this.props;
        let instruction = currentNumber > 2 ? "Félicitations !" : levels[currentLevel].hints[currentNumber];

        return (
            <div className="lock-game-main">
                <div className="game-bg" ref={bg => this.bg = (bg)}></div>
                <ResponsiveElement className="lock-container" ratio={980/757} position={["50%", "55%"]} anchor={["50%", "50%"]} height="75%" maxHeight="750px" maxWidth="50%" fontSizeRef="width">
                    <LockControls turnRing={this.turnRing.bind(this)} step={step} max={max} direction="ccw" animatedElements={this.animatedElements}/>
                    <AnimatedElements animatedElements={this.animatedElements} animation="slide-down">
                        <Lock
                            rotation={this.props.rotations * stepDegres}
                            step={step}
                            max={max}
                            rotationDuration={rotationDuration}
                        />
                    </AnimatedElements>
                    <LockControls turnRing={this.turnRing.bind(this)} step={step} max={max} direction="cw" animatedElements={this.animatedElements}/>
                </ResponsiveElement>

                <div className="ui" ref={this.lock}>
                    <AnimatedElements animatedElements={this.animatedElements} animation="slide-right" animatedClass="code-display">
                        <CodeDisplay combination={combination.map(x => this.getVisualValue(x))} currentNumber={currentNumber} />
                    </AnimatedElements>
                    
                    <AnimatedElements animatedElements={this.animatedElements} animatedClass="level-display">
                        <LevelDisplay currentLevel={currentLevel} />
                    </AnimatedElements>

                    {currentNumber === -1 ?<div className="instruction-overlay"></div> : null}

                    {
                        currentNumber !== -1 && 
                        <AnimatedElements autoStart={true} animatedElements={this.animatedElements} animation="slide-down" animatedClass="instructions">
                            <Instructions
                                currentNumber={currentNumber}
                                skipInstruction={this.skipInstruction.bind(this)}
                                instruction={instruction}
                            />
                        </AnimatedElements>
                    }

                    <AnimatedElements animatedElements={this.animatedElements} animatedClass="exit-button__icon">
                        {/*<MenuButton menuItems={this.getMenuItems()}>
                            <img src="/images/game/general/btn_menu_white.png" alt="" className="menu-image" />
                        </MenuButton>*/}
                        <ExitButton onTransitionStart={this.onTransitionStart.bind(this)} />
                    </AnimatedElements>

                    <AnimatedElements animatedElements={this.animatedElements} animation="scale" animatedClass="info-button-icon">
                        <InfoButton onClick={(e) => {this.openInformations()}} color="white" />
                    </AnimatedElements>
                </div>
            </div>
        );
    }

    componentDidMount() {
        animateIn(this.animatedElements);
        TweenLite.fromTo(this.bg, 0.6, {opacity: 0}, {opacity: 1});

        this.openInformations();
    }

    openInformations() {
        this.props.closeMenu();
        this.props.openPopup(
            <div
                className="lock-game-instruction-popup"
                style={{marginTop: '1em'}}
                dangerouslySetInnerHTML={{__html: strapi.mdToHtml(this.props.instruction)}}
            />,
            {
                title: strapi.data.getText('lock.instruction.title'),
                responsiveElementOptions: {
                    ratio: null,
                    width: '45%',
                    height: '55%',
                },
                actionButtons: [
                    {
                        content: (
                            <div className="lock-game-instruction-button">
                                <GoButton />
                            </div>
                        ),
                        action: () => {
                            this.skipInstruction();
                            this.props.closePopup();
                        }
                    }
                ]
            }
        );
    }

    UNSAFE_componentWillMount() {
        this.generateCombination();
    }

    onTransitionStart(end) {
        this.props.closeMenu();
        TweenLite.to(this.bg, 0.6, {opacity: 0, delay: 0.5});
        this.props.closePopup();
        animateOut(this.animatedElements, {
            onComplete: () => {
                end();
            }
        });
    }

    getMenuItems() {
        return [
            <a className="menu-item" href={locale.getUrl('/jeu/:slug', {slug: locale.textObj(this.props.infos.urlSlug)})} key="restart" onClick={(e) => {
                e.preventDefault();
                this.resetGame();
                this.props.closeMenu();
            }}><Text slug="general.restart"/></a>,
            <a className="menu-item" href="/" key="instructions" onClick={(e) => {
                e.preventDefault();
                this.openInformations();
            }}><Text slug="general.informations"/></a>,
            <TransitionLink className="menu-item" to={locale.getUrl("/jeu")} onTransitionStart={this.onTransitionStart.bind(this)} key="return"><Text slug="general.backtomap"/></TransitionLink>,
            // <TransitionLink className="menu-item" to={locale.getUrl("/")} onTransitionStart={this.onTransitionStart.bind(this)} key="return-home"><Text slug="general.backtohome"/></TransitionLink>
        ];
    }
    generateCombination() {
        let { max, step } = this.props.settings;
        let turnRotationCount = max / step;
        let values = [
            2 * turnRotationCount,
            -turnRotationCount,
            0
        ];
        let startRotation = 0;
        let lastNumber = 0;
        
        for(var i = 0; i < 3; i++) {
            let value = startRotation + values[i];
            let randValue = null;

            while(randValue === null || this.getVisualValue(value + randValue) === lastNumber) {
                randValue = Math.floor(Math.random() * turnRotationCount);
                if(i === 1) {
                    randValue = -randValue;
                }
            }

            lastNumber = this.getVisualValue(value + randValue);
            value += randValue;

            values[i] = value;

            startRotation = value;
        }

        this.props.updateGameStatus({
            combination : values
        });
    }

    // Ring control
    turnRing(rotationCount) {
        if(!this.isRotating) {
            let {currentNumber, combination, rotations} = this.props;
            let goalRotation = combination[currentNumber];
            let resultRotation = rotations + rotationCount;
            let initialDiff = Math.abs(goalRotation - rotations);
            let resultDiff = Math.abs(goalRotation - resultRotation);

            // Validate if we are turning in the right direction
            if(resultDiff > initialDiff || currentNumber === 3) {
                this.onError();
                return false;
            }

            if(rotationCount < 1 && goalRotation > resultRotation) {
                this.onError();
                return false;
            } else if(rotationCount > 1 && goalRotation < resultRotation) {
                this.onError();
                return false;
            }

            // Process turn
            this.processRotation(resultRotation);
        }

        return true;
    }

    onError() {
        if(!this.lock.current.classList.contains('shake')) {
            this.lock.current.classList.add('shake');

            setTimeout(() => {
                this.lock.current.classList.remove("shake");
            }, 350)
        }
    }

    processRotation(rotations) {
        let { rotationDuration } = this.props.settings;
        let {currentNumber, combination} = this.props;
        let goalRotation = combination[currentNumber];

        this.isRotating = true;

        // Update rotation
        this.props.updateGameStatus({
            rotations : rotations
        });

        setTimeout(() => {
            this.isRotating = false;

            // Check if the combination is right
            if(rotations === goalRotation) {
                let {currentNumber} = this.props;

                this.props.updateGameStatus({
                    currentNumber : currentNumber + 1
                });

                if(currentNumber < 2) {
                    this.nextNumber();
                } else {
                    this.openLock();
                }
            }

        }, rotationDuration * 300);
    }

    nextNumber() {
        
    }

    skipInstruction() {
        this.props.updateGameStatus({
            currentNumber : 0
        });
    }

    openLock() {
        let {currentLevel} = this.props;
        const { history } = this.props;

        if(currentLevel < 2) {
            
            this.showSuccessPopup(strapi.data.getText('lock.success.level'+(currentLevel+1)), true, strapi.data.getText('lock.nextlevel'), () => {
                this.nextLevel();
            });
        } else {
            this.showSuccessPopup(strapi.data.getText('lock.success.level3'), false, strapi.data.getText('general.backtomap'), () => {
                this.onTransitionStart(() => {
                    history.push(locale.getUrl("/jeu"));
                });
            }, () => {
                this.resetGame();
            });
        }
    }

    resetGame() {
        this.props.resetGame();
        this.generateCombination();
        setTimeout(() => {
            this.openInformations();
        }, 500);
    }

    nextLevel() {
        let { currentLevel } = this.props;
        let { rotationDuration } = this.props.settings;

        this.generateCombination();
        this.props.updateGameStatus({
            currentLevel : currentLevel + 1,
            currentNumber : 0,
            rotations : 0
        });

        setTimeout(() => {
            this.isRotating = false;
        }, rotationDuration);
    }

    getVisualValue(rotation) {
        let value = rotation * this.props.settings.step % this.props.settings.max;
        value = value < 0 ? value + this.props.settings.max : value;
        value = this.props.settings.max - value;
        value = value === this.props.settings.max ? 0 : value;

        return value;
    }

    /**
     * Popup
     */

    showSuccessPopup(content, showLock, nextText, onNext, onReset = null) {
        let actionButtons = [];

        if(onReset) {
            actionButtons.push({
                type: 'home',
                action: () => {
                    onNext();
                    this.props.closePopup();
                }
            });
            
            actionButtons.push({
                type: 'restart',
                action: () => {
                    onReset();
                    this.props.closePopup();
                }
            });
        }

        let imageStyle = {
            display: 'block',
            height: '13em',
            margin: '0em auto 0.4em auto',
        };

        this.props.openPopup(
            <div style={{margin: '0px'}}>
                {showLock && <img src="/images/game/lock/opened_lock.png" alt="Cadenas ouvert" style={imageStyle} />}
                <div dangerouslySetInnerHTML={{__html: strapi.mdToHtml(content)}}></div>

                {
                    showLock &&
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                    }}>
                        <div className="grow-hover" style={{width: "8em"}} onClick={() => {
                            this.props.closePopup();
                            onNext();
                        }}>
                            <GoButton color="blue" />
                        </div>
                    </div>
                }
            </div>, 
            {
                title: strapi.data.getText('lock.success.title' + (this.props.currentLevel + 1)),
                responsiveElementOptions: {
                    ratio: null,
                    width: '29%',
                    height: '70%',
                },
                actionButtons: actionButtons
            }
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));