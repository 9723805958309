import React, { Component }  from 'react';

import './InfoButton.scss';

class InfoButton extends Component {
	render() {
        return (
            <div className="info-button" onClick={this.props.onClick}>
                <img className="info-button-icon" src={"/images/game/general/btn_info_"+(this.props.color || "blue")+".png"} alt="" />
            </div>
        );
    }
}

export default InfoButton;