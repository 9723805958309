import React, { Component }  from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import * as gameActions from 'redux/actions/gameActions';
import { getGameFromState, getPopupDispatch } from 'utils/games/gameUtils';
import { AnimatedElements, animateIn, animateOut } from 'components/general/AnimatedElements';
import * as strapi from 'utils/strapi';
import * as locale from 'utils/locale';

import TransitionLink from 'components/general/TransitionLink';
import GameButton from 'components/general/GameButton';
import Informations from 'components/general/Informations/Informations';
import Text from 'components/general/Text';

import './Main.scss';
import ExitButton from '../../ExitButton/ExitButton';
import GoButton from '../../../general/GoButton';

const gameSlug = 'personnes-ressources';

const mapStateToProps = (state, ownProps) => {
    return {
        ...getGameFromState(gameSlug, state),
    }
}

const mapDispatchToProps = dispatch => ({
    updateGameStatus: (status = {}) => dispatch(gameActions.updateGameStatus(gameSlug, status)),
    resetGame: (status = {}) => dispatch(gameActions.resetGame(gameSlug, status)),
    closeMenu: (menuItems) => dispatch(gameActions.closeMenu(menuItems)),
    ...getPopupDispatch(dispatch),
});

class Main extends Component {
    constructor(props) {
        super(props);

        this.animatedElements = [];
        this.questions = this.getAvailableElements(props.questions);
        this.professionals = this.getAvailableElements(props.professionals);

        this.state = {
            overlayCharacter: null,
        }
    }

	render() {
        return (
            <div className="professionals-game-main">  
                {this.renderCharacters()}              

                <div className="ui">
                    {this.renderInstructions()}
                    {/*<MenuButton menuItems={this.getMenuItems()}>
                        <img src="/images/game/general/btn_menu_white.png" alt="" className="menu-image" />
                    </MenuButton>*/}
                    <ExitButton onTransitionStart={this.onTransitionStart.bind(this)} />

                    {/*<AnimatedElements animatedElements={this.animatedElements} animation="scale" animatedClass="info-button-icon">
                        <InfoButton onClick={(e) => {this.openInformations()}} color="white" />
                    </AnimatedElements>*/}
                </div>
                {this.state.overlayCharacter}
            </div>
        );
    }

    renderInstructions() {
        let question = this.getCurrentQuestion();

        if(!question) {
            return null;
        }

        let description = locale.textObj(question.description);
        let questionStr = locale.textObj(question.question);
        
        return (
            <div className="instructions">
                {/*<div className="name-box">{nameBox}</div>*/}
                <div className="text">
                    <div className="description" dangerouslySetInnerHTML={{__html:description}}/>
                    <div className="question" dangerouslySetInnerHTML={{__html:questionStr}}/>
                </div>
            </div>
        );
    }

    renderCharacters() {
        let {characters, characterIds, answers} = this.props;
        let caractersDom = [];
        let professionals = this.professionals;

        for(let i = 0; i < characterIds.length; i++) {
            let characterId = characterIds[i];
            let professional = professionals[answers[i]];
            let image = characters[characterId];
            let name = locale.textObj(professional.name);

            caractersDom.push(
                <div className={"character character-"+characterId} key={i} onClick={(e) => { this.validateAnswer(e.currentTarget, answers[i], professional, image, characterId) }}>
                    <img src={image} alt={name} title={name} />
                    <div className="name-container">
                        <div className="name">
                            {name}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <AnimatedElements animatedElements={this.animatedElements} animation="slide-up" animatedClass="characters">
                <div className="characters">
                    {caractersDom}
                </div>
            </AnimatedElements>
        );
    }

    validateAnswer(target, answer, professional, image, characterId) {
        let question = this.getCurrentQuestion();
        let isGood = question.answer === answer || question.otherAnswers.indexOf(answer) !== -1;

        if(!target.classList.contains('selected-option')) {
            target.classList.add('selected-option');
        }

        let name = locale.textObj(professional.name);
        let description = locale.textObj(professional.description);

        if(!isGood) {
            description = strapi.data.getText('professionals.fail');
        }

        let characterTemp = (<div className={"character character-temp character-"+characterId}>
            <img src={image} alt={name} title={name} />
            <div className="name-container">
                <div className="name">
                    {name}
                </div>
            </div>
        </div>);

        this.setState({
            overlayCharacter: characterTemp,
        });

        this.props.openPopup(
            <div className="professionals-success-popup-content">
                <h2>{isGood ? strapi.data.getText('general.true') : strapi.data.getText('general.false')}</h2>
                <p dangerouslySetInnerHTML={{__html:strapi.mdToHtml(description)}} />
                <div className='grow-hover center next-button'>
                    <GoButton width="12em" onClick={() => {
                        target.classList.remove('selected-option');
                        this.setState({
                            overlayCharacter: null,
                        });

                        if(isGood) {
                            this.nextQuestion();
                        } else {
                            this.props.closePopup();
                        }
                    }} />
                </div>
            </div>, 
            {
                contentStyle: {
                    padding: '0.7em 5em 3em 5em',
                    height: '100%',
                },
                responsiveElementOptions: {
                    ratio: 1.5,
                    width: '50%',
                },
            }
        );
    }

    

    nextQuestion() {
        let {currentQuestion, numberOfQuestions} = this.props;
        const { history } = this.props;
        
        if(currentQuestion === numberOfQuestions - 1) { // Finish
            this.props.openPopup(
                <p style={{marginTop: '1em'}}>
                    <Text slug="professionals.end"/>
                </p>, 
                {
                    title: strapi.data.getText('game.success'),
                    responsiveElementOptions: {
                        ratio: null,
                        width: '30%',
                        height: '50%',
                    },
                    actionButtons: [
                        {
                            type: 'home',
                            action: () => {
                                this.onTransitionStart(() => {
                                    history.push(locale.getUrl("/jeu"));
                                });
                            }
                        },
                        {
                            type: 'restart',
                            action: () => {
                                this.resetGame();
                                this.props.closePopup();
                            }
                        },
                    ]
                }
            );
        } else { // Next
            this.prepareNextQuestion();
            this.props.closePopup();
        }
    }

    lastQuestion() {
        
    }

    componentDidMount() {
        animateIn(this.animatedElements);
    }

    UNSAFE_componentWillMount() {
        this.prepareFirstQuestion();
    }

    prepareFirstQuestion() {
        let questionIds = this.generateQuestionIds();
        let characterIds = this.generateCharactersIds();
        let answers = this.generateAnswers(0, questionIds);

        this.props.updateGameStatus({
            questionIds : questionIds,
            characterIds : characterIds,
            answers : answers,
        });
    }

    prepareNextQuestion() {
        let questionId = this.props.currentQuestion + 1;
        let characterIds = this.generateCharactersIds();
        let answers = this.generateAnswers(questionId);

        this.props.updateGameStatus({
            currentQuestion : questionId,
            characterIds : characterIds,
            answers : answers,
        });
    }

    getCurrentQuestion() {
        let {currentQuestion, questionIds} = this.props;
        let questions = this.questions;

        return questions[questionIds[currentQuestion]];
    }

    generateAnswers(questionId, questionIds = null) {
        if(questionIds === null) {
            questionIds = this.props.questionIds;
        }
        let questions = this.questions;
        let professionals = this.professionals;

        let professionalsLength = Object.keys(professionals);

        // Add the first answer and remove it from the array
        let answer = questions[questionIds[questionId]]['answer'];
        let answerKey = Object.keys(professionalsLength).find(key => professionalsLength[key] === answer);
        professionalsLength.splice(answerKey, 1);
        let answers = [answer];

        for(let i = 0; i < 3; i++) {
            let key = Math.floor(Math.random() * professionalsLength.length);
            answers.push(professionalsLength[key]);
            professionalsLength.splice(key, 1);
        }

        let shuffledAnswers = [];

        while(answers.length > 0) {
            let key = Math.floor(Math.random() * answers.length);
            shuffledAnswers.push(answers[key]);
            answers.splice(key, 1);
        }

        return shuffledAnswers;
    }

    generateQuestionIds() {
        let {numberOfQuestions} = this.props;
        let questions = this.questions;
        let questionsLength = Array.from(questions.keys());
        let questionIds = [];

        for(let i = 0; i < numberOfQuestions; i++) {
            let key = Math.floor(Math.random() * questionsLength.length);
            questionIds.push(questionsLength[key]);
            questionsLength.splice(key, 1);
        }

        return questionIds;
    }

    generateCharactersIds() {
        let {characters} = this.props;
        let charactersLength = Array.from(characters.keys());
        let characterIds = [];

        for(let i = 0; i < 4; i++) {
            let key = Math.floor(Math.random() * charactersLength.length);
            characterIds.push(charactersLength[key]);
            charactersLength.splice(key, 1);
        }

        return characterIds;
    }

    loadQuestion() {

    }

    onTransitionStart(end) {
        this.props.closeMenu();
        this.props.closePopup();
        
        animateOut(this.animatedElements, {
            onComplete: () => {
                end();
            }
        });
    }

    resetGame() {
        this.props.resetGame();
        this.prepareFirstQuestion();
    }

    getMenuItems() {
        return [
            <a className="menu-item" href={locale.getUrl('/jeu/:slug', {slug: locale.textObj(this.props.infos.urlSlug)})} key="restart" onClick={(e) => {
                e.preventDefault();
                this.resetGame();
                this.props.closeMenu();
            }}><Text slug="general.restart"/></a>,
            <a className="menu-item" href="/" key="instructions" onClick={(e) => {
                e.preventDefault();
                this.openInformations();
            }}><Text slug="general.informations"/></a>,
            <TransitionLink className="menu-item" to={locale.getUrl("/jeu")} onTransitionStart={this.onTransitionStart.bind(this)} key="return"><Text slug="general.backtomap"/></TransitionLink>,
            // <TransitionLink className="menu-item" to={locale.getUrl("/")} onTransitionStart={this.onTransitionStart.bind(this)} key="return-home"><Text slug="general.backtohome"/></TransitionLink>
        ];
    }

    openInformations() {
        this.props.closeMenu();
        this.props.openPopup(
            <Informations gameSlug={gameSlug} />
            , {
                title: strapi.data.getText('general.informations'),
                responsiveElementOptions: {
                    fontSizeRef: 'width',
                    ratio: 1.1,
                    width: '80%',
                },
                autoAdjust: false,
                actionButtons: [
                    {
                        content: (
                            <GameButton fontSize="2em"><Text slug="general.close"/></GameButton>
                        ),
                        action: () => {
                            this.props.closePopup();
                        }
                    }
                ]
            }
        )
    }

    getAvailableElements(elements) {
        let isArray = Array.isArray(elements);
        let filteredElements = isArray ? [] : {};
        let attribute = locale.langSuffix('available', locale.getLang());

        if(isArray) {
            elements.forEach(element => {
                if(element[attribute]) {
                    filteredElements.push(element);
                }
            });
        } else {
            for(let key in elements) {
                let element = elements[key];
                if(element[attribute]) {
                    filteredElements[key] = element;
                }
            }
        }
        
        return filteredElements;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));