import LockGame from 'components/game/LockGame/LockGame';
import ExtracurricularGame from 'components/game/ExtracurricularGame/ExtracurricularGame';
import ProfessionalsGame from 'components/game/ProfessionalsGame/ProfessionalsGame';
import LockerGame from 'components/game/LockerGame/LockerGame';
import DeplacementsGame from 'components/game/DeplacementsGame/DeplacementsGame';
// import SocialGame from 'components/game/SocialGame/SocialGame';
import ProgramsGame from 'components/game/ProgramsGame/ProgramsGame';
import AgendaGame from 'components/game/AgendaGame/AgendaGame';
// import SchoolGame from 'components/game/SchoolGame/SchoolGame';
// import CovidGame from 'components/game/CovidGame/CovidGame';

export default {
    "cadenas" : LockGame,
    "parascolaires" : ExtracurricularGame,
    "personnes-ressources" : ProfessionalsGame,
    "casier" : LockerGame,
    "en-route-vers-le-secondaire" : DeplacementsGame,
    // "vie-sociale" : SocialGame,
    "programmes" : ProgramsGame,
    "agenda" : AgendaGame,
    // "entree-au-secondaire" : SchoolGame,
    // "covid-19" : CovidGame,

    // EN
    "combination-lock" : LockGame,
    "extracurricular" : ExtracurricularGame,
    "resources" : ProfessionalsGame,
    "locker" : LockerGame,
    "on-the-road-to-high-school" : DeplacementsGame,
    // "social-life" : SocialGame,
    "programs" : ProgramsGame,
    // "starting-high-school" : SchoolGame,
};