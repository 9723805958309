export const openGame = gameId => dispatch => {
    dispatch({
        type: 'OPEN_GAME',
        payload: {
            gameId : gameId,
        }
    })
}

export const resetGame = (gameSlug, status = {}) => dispatch => {
    dispatch({
        type: 'RESET_GAME',
        payload: {
            gameSlug : gameSlug,
            status : status,
        }
    })
}

export const updateGameStatus = (gameSlug, status = {}) => dispatch => {
    dispatch({
        type: 'UPDATE_GAME_STATUS',
        payload: {
            gameSlug : gameSlug,
            status : status,
        }
    })
}

export const openMenu = (menuItems) => dispatch => {
    dispatch({
        type: 'OPEN_MENU',
        payload: {
            menuItems : menuItems
        }
    })
}

export const closeMenu = () => dispatch => {
    dispatch({
        type: 'CLOSE_MENU',
        payload: {}
    })
}

export const updateResolution = () => dispatch => {
    dispatch({
        type: 'UPDATE_RESOLUTION',
        payload: {}
    })
}