import React, { Component }  from 'react';
import { connect } from 'react-redux';
import * as Phys from "react-dom-box2d";
import { withRouter } from "react-router-dom";

import { getGameFromState, getPopupDispatch } from 'utils/games/gameUtils';
import * as gameActions from 'redux/actions/gameActions';
import { AnimatedElements, animateIn, animateOut } from 'components/general/AnimatedElements';
import * as strapi from 'utils/strapi';
import * as locale from 'utils/locale';

import TransitionLink from 'components/general/TransitionLink';
import ResponsiveElement from 'components/general/ResponsiveElement';
import GameButton from 'components/general/GameButton';
import Informations from 'components/general/Informations/Informations';
import Text from 'components/general/Text';

import './Main.scss';
import ExitButton from '../../ExitButton/ExitButton';
import GoButton from '../../../general/GoButton';

const gameSlug = 'parascolaires';

const mapStateToProps = (state, ownProps) => {
    return {
        ...getGameFromState(gameSlug, state),
        resolution: state.gameReducer.resolution,
        schools : state.schoolsReducer.schools,
    }
}

const mapDispatchToProps = dispatch => ({
    updateGameStatus: (status = {}) => dispatch(gameActions.updateGameStatus(gameSlug, status)),
    resetGame: (status = {}) => dispatch(gameActions.resetGame(gameSlug, status)),
    closeMenu: (menuItems) => dispatch(gameActions.closeMenu(menuItems)),
    ...getPopupDispatch(dispatch),
});

class Main extends Component {
    constructor(props) {
        super(props);

        this.animatedElements = [];

        this.state = {
            showSelectionPopup: false,
            selection: null,
        }
    }

	render() {
        //let {schools, schoolSelection} = this.props;
        //let schoolName = schools[schoolSelection].name;

        return (
            <div className="extracurricular-game-main">
                <div className="ui">
                    {/*<MenuButton menuItems={this.getMenuItems()}>
                        <img src="/images/game/general/btn_menu_blue.png" alt="" className="menu-image" />
                    </MenuButton>*/}
                    <ExitButton onTransitionStart={this.onTransitionStart.bind(this)} />

                    {/*<AnimatedElements animatedElements={this.animatedElements} animation="scale" animatedClass="info-button-icon">
                        <InfoButton onClick={(e) => {this.openInformations()}} color="blue" />
                    </AnimatedElements>*/}

                    <AnimatedElements animatedElements={this.animatedElements} animatedClass="instructions">
                        <ResponsiveElement className="instructions" height="15%" width="40%" position={["50%", "5%"]} anchorX="50%" fontSizeRef="window-height">
                            
                            <div className="school">
                                <Text slug="extracurricular.selector.title" />
                            </div>
                            {/*
                            <GameButton className="prev-button" onClick={this.prevStep.bind(this)}>
                                <Text slug="general.modify"/>
                            </GameButton>
                            */}
                            <div className="text">
                                <Text slug="extracurricular.instructions"/>
                            </div>
                        </ResponsiveElement>
                    </AnimatedElements>
                </div>

                {this.renderActivites()}
            </div>
        );
    }

    prevStep(e) {
        e.preventDefault();
        this.onTransitionStart(() => {
            this.props.updateGameStatus({
                screen: 0
            });
        }, true);
    }

    renderActivites() {
        let activitiesDom = [];
        let {activities, resolution, schoolSelection, categorySelection} = this.props;
        let size = Math.max(resolution.x / 6);
        
        for(let i = 0; i < activities.length; i++) {
            let forceX = (Math.random() - 0.5) * resolution.x / 10;
            let activity = activities[i];

            // Filter
            if(schoolSelection !== null && activity.schools.indexOf(schoolSelection) === -1) {
                continue;
            }
            if(categorySelection !== null && activity.categories.indexOf(categorySelection) === -1) {
                continue;
            }
            if(schoolSelection == null && !activity.showInAll) {
                continue;
            }

            let content = activity.icon !== "" ? <img className="icon-image" src={activity.icon} alt={activity.name} /> : activity.name;

            activitiesDom.push(
                <AnimatedElements animatedElements={this.animatedElements} animatedClass="icon-image" skipIntro={true} key={i}>
                    <Phys.Item
                        shape="circle"
                        left={Math.random() * (resolution.x * 0.8) + resolution.x * 0.1}
                        top={Math.random() * resolution.y / 3 * 0.8}
                        initialImpulse={[forceX / resolution.y * 3000, 0]}
                        restitution={0.8}
                        friction={0.1}
                        width={size}
                        height={size}
                    >
                        <div className={"activity-ball" + (activity.icon !== "" ? " icon" : " text")} onClick={() => this.selectActivity(activity)}>
                            <div className="ball-content">
                                {content}
                            </div>
                        </div>
                    </Phys.Item>
                </AnimatedElements>
            );
        }

        return (
            <Phys.World width={resolution.x} height={resolution.y * 0.9 + 400} gravity={[0, resolution.y / 100]} className="world">
                {activitiesDom}
            </Phys.World>
        );
    }

    componentDidMount() {
        animateIn(this.animatedElements);
    }

    UNSAFE_componentWillMount() {
        
    }

    onTransitionStart(end, inGame = false) {
        this.props.closeMenu();
        this.props.closePopup();

        if(!inGame) {
            this.props.parentWillUnmount();
        }
        
        animateOut(this.animatedElements, {
            onComplete: () => {
                end();
            }
        });
    }

    getMenuItems() {
        return [
            <a className="menu-item" href="/" key="instructions" onClick={(e) => {
                e.preventDefault();
                this.openInformations();
            }}><Text slug="general.informations"/></a>,
            <TransitionLink className="menu-item" to={locale.getUrl("/jeu")} onTransitionStart={this.onTransitionStart.bind(this)} key="return"><Text slug="general.backtomap"/></TransitionLink>,
            // <TransitionLink className="menu-item" to={locale.getUrl("/")} onTransitionStart={this.onTransitionStart.bind(this)} key="return-home"><Text slug="general.backtohome"/></TransitionLink>
        ];
    }

    openInformations() {
        this.props.closeMenu();
        this.props.openPopup(
            <Informations gameSlug={gameSlug} />
            , {
                title: strapi.data.getText('general.informations'),
                responsiveElementOptions: {
                    fontSizeRef: 'width',
                    ratio: 1.1,
                    width: '80%',
                },
                autoAdjust: false,
                actionButtons: [
                    {
                        content: (
                            <GameButton fontSize="2em"><Text slug="general.close"/></GameButton>
                        ),
                        action: () => {
                            this.props.closePopup();
                        }
                    }
                ]
            }
        )
    }

    resetGame() {
        this.props.resetGame();
    }

    selectActivity(activity) {
        //let {schools} = this.props;

        // Get information
        let name = locale.textObj(activity.name);
        let subtitle = locale.textObj(activity.subtitle);
        // let icon = _.get(activity, 'icon', '');

        this.props.openPopup(
            <div className="extracurricular-popup-content">
                <div className="informations">
                    {/*<img src={icon} title={name} alt={name}/>*/}
                    <h3 dangerouslySetInnerHTML={{__html: subtitle}} />
                    <p className="schools" dangerouslySetInnerHTML={{__html: strapi.mdToHtml(locale.textObj(activity.description))}} />
                </div>
            </div>, 
            {
                title: name,
                responsiveElementOptions: {
                    ratio: 1,
                    height: '60%',
                    width: null,
                },
                actionButtons: [
                    {
                        content: <GoButton color="blue" width="10em" onClick={this.closeActivityPopup.bind(this)} />,
                    },
                ]
            }
        );
    }

    closeActivityPopup(e) {
        e.preventDefault();
        
        this.props.closePopup();
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));