import React, { Component } from 'react';

import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';

import './ScrollingDiv.scss';

class ScrollbarIndicator extends ScrollbarPlugin {
	static pluginName = 'scrollbarIndicator';

	static defaultOptions = {
		bottomIndicator: null,
		topIndicator: null,
	};
  
	onRender() {
		let {limit, offset} = this.scrollbar;

		if(limit.y > offset.y) {
			this.options.bottomIndicator.classList.remove('hidden');
		} else {
			if(!this.options.bottomIndicator.classList.contains('hidden')) {
				this.options.bottomIndicator.classList.add('hidden');
			}
		}

		if(offset.y > 0) {
			this.options.topIndicator.classList.remove('hidden');
		} else {
			if(!this.options.topIndicator.classList.contains('hidden')) {
				this.options.topIndicator.classList.add('hidden');
			}
		}
	}
}

class ScrollingDiv extends Component {
	constructor(props) {
		super(props);

		this.scrollbar = null;
		this.bottomIndicator = null;
		this.topIndicator = null;
	}

	render() {
		return <div className="scroll-bar">
			<div style={{height: "100%"}} ref={scrollbar => this.scrollbar = scrollbar}>
				{this.props.children}
			</div>
			<div className="indicator bottom" ref={bottomIndicator => this.bottomIndicator = bottomIndicator}></div>
			<div className="indicator top" ref={topIndicator => this.topIndicator = topIndicator}></div>
		</div>;
	}

	componentDidMount() {
		Scrollbar.use(ScrollbarIndicator);

		Scrollbar.init(this.scrollbar, {
			plugins: {
				scrollbarIndicator : {
					bottomIndicator: this.bottomIndicator,
					topIndicator: this.topIndicator,
				}
			}
		});
	}
}

export default ScrollingDiv;