import { combineReducers } from 'redux';
import tipsReducer from './tipsReducer';
import schoolsReducer from './schoolsReducer';
import gameReducer from './gameReducer';
import partnersReducer from './partnersReducer';
import popupReducer from './popupReducer';
import siteReducer from './siteReducer';

export default combineReducers({
    schoolsReducer,
    tipsReducer,
    gameReducer,
    partnersReducer,
    popupReducer,
    siteReducer,
});