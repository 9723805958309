import React, { Component }  from 'react';

import ResponsiveElement from 'components/general/ResponsiveElement';

import './Instructions.scss';

class Instructions extends Component {
    tranformText(text) {
        // Replace arrows
        text = text.replace(/\(>>\)/g, '<img src="/images/game/lock/arrow-full-ccw.png" class="reversed" />');
        text = text.replace(/\(>\)/g, '<img src="/images/game/lock/arrow-step-ccw.png" class="reversed" />');
        text = text.replace(/\(<<\)/g, '<img src="/images/game/lock/arrow-full-ccw.png" />');
        text = text.replace(/\(<\)/g, '<img src="/images/game/lock/arrow-step-ccw.png" />');

        // Bold
        text = text.replace(/<b>/g, '<span class="bold">');
        text = text.replace(/<\/b>/g, '</span>');

        // Replace line breaks
        text = text.replace(/\n/g, '<br/>');

        // Replace "-" by list items
        text = text.replace(/- /g, '<span class="list-item"></span>');

        return {__html: text};
    }
    
    render() {
        let step = "#" + (Math.min(this.props.currentNumber, 2) + 1);
        let action = null;

        if(this.props.currentNumber === -1) {
            step = '!';
            action = <div className="next" onClick={this.props.skipInstruction}></div>
        }

        return (
            <ResponsiveElement
                className="instructions"
                ratio={1014/225}
                top="0px"
                left="50%"
                anchorX="50%"
                width="45%"
                maxWidth="950px"
            >
                <div className="content">
                    <div className="step">
                        {step}
                    </div>
                    <div className="text" dangerouslySetInnerHTML={this.tranformText(this.props.instruction)}>
                        {this.props.children}
                    </div>
                </div>
                {action}
            </ResponsiveElement>
        );
    }
}

export default Instructions;