import React, { Component }  from 'react';
import './FullScreenButton.scss';

class FullScreenButton extends Component {
	render() {     
        return (
            <div className="fullscreen-button" onClick={this.toggleFullscreen.bind(this)}>
                <img src="/images/game/general/btn_full_screen.png" alt=""/>
            </div>  
        )
    }

    toggleFullscreen() {
		if(document.fullscreenElement !== null) {
			document.exitFullscreen();
		} else {
			var elem = document.getElementById("root");

			if (elem.requestFullscreen) {
				elem.requestFullscreen();
			} else if (elem.mozRequestFullScreen) { /* Firefox */
				elem.mozRequestFullScreen();
			} else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
				elem.webkitRequestFullscreen();
			} else if (elem.msRequestFullscreen) { /* IE/Edge */
				elem.msRequestFullscreen();
			}
		}
	}
}

export default FullScreenButton;