import React, { Component }  from 'react';
import { connect } from 'react-redux';
import { TweenLite } from "gsap/TweenMax";

import { getGameFromState } from 'utils/games/gameUtils';
import * as gameActions from 'redux/actions/gameActions';

import Main from './Main/Main';

import './ExtracurricularGame.scss';

const gameSlug = 'parascolaires';

const mapStateToProps = (state, ownProps) => {
    return {
        ...getGameFromState(gameSlug, state),
    }
}

const mapDispatchToProps = dispatch => ({
    resetGame: (status = {}) => dispatch(gameActions.resetGame(gameSlug, status)),
});

class ExtracurricularGame extends Component {
    constructor(props) {
        super(props);

        this.bg = null;
    }

	render() {
        return (
            <div className="extracurricular-game">
                <div className="game-bg" ref={bg => this.bg = (bg)}></div>
                {this.renderGame()}
            </div>
        );
    }

    UNSAFE_componentWillMount() {
        this.props.resetGame();
    }

    componentDidMount() {
        TweenLite.fromTo(this.bg, 0.6, {opacity: 0}, {opacity: 1});
    }

    parentWillUnmount() {
        TweenLite.to(this.bg, 0.6, {opacity: 0, delay: 0.5});
    }

    renderGame() {
        switch(this.props.screen) {
            /*case 0 :
                return <Selector parentWillUnmount={this.parentWillUnmount.bind(this)} />
            case 1 :*/
            case 0 :
                return <Main parentWillUnmount={this.parentWillUnmount.bind(this)} />
            default :
                return null;
        }
    }

    resetGame(e) {
        e.preventDefault();
        this.props.resetGame(gameSlug, {
            screen : 0
        });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExtracurricularGame);