import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';

import * as locale from 'utils/locale';

import './LanguageSwitcher.scss';

const mapStateToProps = (state, ownProps) => {
    return {
        urls: state.siteReducer.urls,
    }
}

class LanguageSwitcher extends Component {
	render() {
		let lang = locale.getSwitcherLanguages()[0];
		let url = _.get(this.props.urls, lang, '');

		return (
			<div className="language-switcher">
				<a href={url}>{lang}</a>
			</div>
		);
	}
}

export default connect(mapStateToProps)(LanguageSwitcher);