import React, { Component }  from 'react';
import { connect } from 'react-redux';
import { TweenLite } from "gsap/TweenMax";

import * as popupActions from 'redux/actions/popupActions';
import * as siteActions from 'redux/actions/siteActions';
import * as locale from 'utils/locale';

import GameHeader from './GameHeader/GameHeader';
import Footer from './Footer/Footer';
import animateScrollTo from 'animated-scroll-to';

import './Index.scss';

const mapStateToProps = (state, ownProps) => {
	return {
		...state.partnersReducer
	}
}

const mapDispatchToProps = dispatch => ({
    closePopup: () => dispatch(popupActions.closePopup()),
    openPopup: (content, options = {}) => dispatch(popupActions.openPopup(content, options)),
    setUrls: (urls) => dispatch(siteActions.setUrls(urls)),
});


class Index extends Component {
    constructor(props) {
        super(props);

        this.page = null;
    }

	render() {
        return (
            <div className="static-index" ref={page => this.page = page}>                
                <GameHeader onTransitionStart={this.onTransitionStart.bind(this)}/>
                {/*
                <CtaBlock />
                <Tips onTransitionStart={this.onTransitionStart.bind(this)} />
                <UsefulLinks />
                
                <Partners title="home.financialPartners.title" partners={this.props.financialPartners}>
                    <Text slug="home.financialPartners.subtitle"/><br />
                    <img src="/images/partners/table5-12.png" alt="Table 5-12 ans de Rosemont" className="logo-table" />
                </Partners>
                <Partners title="home.partners.title" partners={this.props.partners}></Partners>
                */}
                <Footer />
                {/*
                <ArrowDown />
                <ArrowUp />
                */}
            </div>
        );
    }

    goToCovid() {
        let el = document.querySelector('.questions li.covid-19:not(.active) .question');

        if(el) {
            el.click();
        } else {
            el = document.querySelector('.questions li.covid-19 .question');
        }

        let relativeTop = el.getBoundingClientRect().top;
        let top = parseInt(relativeTop - 100);

        animateScrollTo(top, {
            easing: t => t,
            speed: 1000 / Math.abs(window.scrollY - top) * 350,
        });
    }

    goToLinks() {
        let el = document.querySelector('.questions li.Liens.utiles:not(.active) .question');

        if(el) {
            el.click();
        } else {
            el = document.querySelector('.questions li.Liens.utiles .question');
        }

        let relativeTop = el.getBoundingClientRect().top;
        let top = parseInt(relativeTop - 100);

        animateScrollTo(top, {
            easing: t => t,
            speed: 1000 / Math.abs(window.scrollY - top) * 350,
        });
    }

    goToFooter() {
        let el = document.querySelector('.static-footer');

        let relativeTop = el.getBoundingClientRect().top;
        let top = parseInt(relativeTop);

        animateScrollTo(top, {
            easing: t => t,
            speed: 1000 / Math.abs(window.scrollY - top) * 350,
        });
    }

    componentDidMount() {
        TweenLite.fromTo(this.page, 0.6, {opacity: 0}, {opacity: 1});
        this.props.setUrls(locale.getUrls('/'));

        if(this.props.location.hash === '#covid') {
			this.goToCovid();
        }
        
        if(this.props.location.hash === '#links') {
			this.goToLinks();
        }
        
        if(this.props.location.hash === '#footer') {
			this.goToFooter();
		}
    }

    onTransitionStart(end) {
        TweenLite.to(this.page, 0.6, {
            opacity: 0,
            onComplete: () => {
                end();
            }
        });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);