import React, { Component }  from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import * as gameActions from 'redux/actions/gameActions';

import Text from 'components/general/Text';

import './Menu.scss';

const mapStateToProps = (state) => {
    return {
        ...state.gameReducer.menu
    }
}

const mapDispatchToProps = dispatch => ({
    closeMenu: (menuItems) => dispatch(gameActions.closeMenu(menuItems)),
});

class Menu extends Component {
	render() {     
        let {menuItems, opened} = this.props;

        return (
            <div className={"menu menu-" + (opened ? 'opened' : 'closed')}>
                <div className="menu-bg"></div>
                <div className="menu-content">
                    <div className="menu-title"><Text slug="game.menu"/></div>
                    {menuItems}
                </div>
                <div className="close-button" onClick={this.closeMenu.bind(this)}>
                    <span>X</span>
                </div>
            </div>
        );
    }

    closeMenu() {
        this.props.closeMenu();
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));