import React, { useMemo } from 'react';

function GoButton(props) {
    const { animated, color, width } = props;

    const randomAnimation = useMemo(() => {
        return Math.floor(Math.random() * 3) + 1;
    }, []);

    const style = useMemo(() => {
        const style = { display: 'block' };

        if(width) {
            style.width = width;
        }

        if(props.onClick) {
            style.cursor = 'pointer';
        }
        
        return style;
    }, [width, props.onClick]);

    const classNames = [];

    if(animated) {
        classNames.push('floating-button-' + randomAnimation);
    }

    return (
        <img
            style={style}
            className={classNames.join(' ')}
            src={"/images/graphic-buttons/go_arrow_" + color + ".svg"}
            alt="Go"
            onClick={props.onClick ? props.onClick : null}
        />
    )
}

GoButton.defaultProps = {
    color: 'blue',
    animated: true,
    width: null,
}

export default GoButton;