import React, { Component, Fragment }  from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from "react-router-dom";

import * as gameActions from 'redux/actions/gameActions';
import withTracker from 'utils/withTracker';
import * as locale from 'utils/locale';

import Index from 'components/static/Index';
import Game from 'components/game/Game/Game';
import Popup from 'components/general/Popup/Popup';
import LanguageSwitcher from 'components/general/LanguageSwitcher/LanguageSwitcher';
import AppContainer from 'components/AppContainer';

const mapDispatchToProps = dispatch => ({
	updateResolution: () => dispatch(gameActions.updateResolution()),
});

class App extends Component {
	constructor(props) {
        super(props);

		this.props.updateResolution();
    }

	render() {
		const supportsHistory = 'pushState' in window.history;
		//const NotFound = () => <div>Page not found</div>;

		return (
			<BrowserRouter forceRefresh={!supportsHistory}>
				<Switch location={window.location}>
					<AppContainer>
						<Route path="/:lang([a-z]{2})">
							{
								({match, location}) => {
									const params = match ? match.params : {};
									const {lang} = params;

									// Save the language
									locale.setLang(lang);

									return (
										<Fragment>
											<Route exact path={locale.getUrl('/')} component={withTracker(Index)} />
											<Route path={locale.getUrl('/jeu')} component={withTracker(Game)} />
											{/*<Route component={NotFound} />*/}
										</Fragment>
									)
								}
							}
							
						</Route>
					</AppContainer>
				</Switch>
				<Popup />
				<LanguageSwitcher />
			</BrowserRouter>
		);
	}

	componentDidMount() {
        let {updateResolution} = this.props;
        window.addEventListener('resize', updateResolution);
    }
    
    componentWillUnmount() {
        let {updateResolution} = this.props;
        window.removeEventListener('resize', updateResolution);
	}
}

export default connect(null, mapDispatchToProps)(App);