export default (state = {}, action) => {
    switch (action.type) {
        case 'CLOSE_POPUP':
            return closePopup(state, action);
        case 'OPEN_POPUP':
            return openPopup(state, action);
        default:
            return state
    }
}

function closePopup(state = {}, action) {
    let newState = {
        ...state,
        isOpened: false,
    };

    return newState;
}

function openPopup(state = {}, action) {
    let { content, options } = action.payload;

    let defaultStatus = JSON.parse(JSON.stringify(state.defaultStatus));

    let newState = {
        ...state,
        ...defaultStatus,
        isOpened: true,
        content: content,
        contentStyle: {},
        ...options,
    };

    newState.uniqid = Math.round(Math.random() * 1000000);

    return newState;
}