import React, { Component }  from 'react';

import ResponsiveElement from 'components/general/ResponsiveElement';

import './AnswerHandle.scss';
import Text from '../../../../general/Text';

class AnswerHandle extends Component {

    static defaultProps = {
        draggable: false,
        clickable: false,
    }

    constructor(props) {
        super(props);

        this.dragging = false;
        
        this.distance = null;
        this.draggingStartX = null;
        this.draggingLastX = null;
        this.draggingLastTime = null;
        this.velocity = null;
    }

    /**
     * MOUSE EVENTS
     */

    onMouseDown(e) {
        const clientX = e.clientX || e.touches[0].clientX;

        this.dragging = true;
        this.draggingStartX = clientX;

        // Reset variables
        this.velocity = 0;
        this.distance = 0;
        this.draggingLastX = null;
        this.draggingLastTime = null;
    }

    onMouseMove(e) {
        const clientX = e.clientX || e.touches[0].clientX;

        if(this.dragging) {
            this.distance = clientX - this.draggingStartX;

            if(this.draggingLastX !== null) {
                this.velocity = Math.abs(clientX - this.draggingLastX) / (new Date().getTime() - this.draggingLastTime);
            }

            this.draggingLastX = clientX;
            this.draggingLastTime = new Date().getTime();
        }
    }

    onMouseUp(e) {
        if(!this.dragging) {
            return;
        }

        this.dragging = false;

        // Calculate min distance based on the screen width
        const minDistance = window.innerWidth * 0.1;

        if(!this.props.draggable) {
            return;
        }

        if(this.velocity > 0.1 && Math.abs(this.distance) > minDistance) {
            this.props.sendAnswer(this.distance > 0);
        }
    }

    onAnswerClick(response, e) {
        if(!this.props.clickable) {
            return;
        }

        this.props.sendAnswer(response);
    }

    /**
     * RENDER
     */
	render() {
        return (
            <ResponsiveElement
                className={"answer-handle"}
                left="50%"
                height="60%"
                top="60%"
                anchorY="50%"
                anchorX="50%"
                width="60%"
            >
                <div
                    className="answer-handle_inner"

                    // Mouse events
                    onMouseDown={this.onMouseDown.bind(this)}
                    onMouseMove={this.onMouseMove.bind(this)}
                    onMouseUp={this.onMouseUp.bind(this)}
                    onClick={this.onMouseUp.bind(this)}

                    // Touch events
                    onTouchStart={this.onMouseDown.bind(this)}
                    onTouchMove={this.onMouseMove.bind(this)}
                    onTouchEnd={this.onMouseUp.bind(this)}
                >
                    <div className="answer-handle_direction answer-handle_direction--left">
                        <div className="answer-handle_direction button" onClick={this.onAnswerClick.bind(this, false)}>
                            <div className="label"><Text slug="general.false" /></div>
                        </div>
                    </div>
                    <div className="answer-handle_direction answer-handle_direction--right">
                        <div className="answer-handle_direction button" onClick={this.onAnswerClick.bind(this, true)}>
                            <div className="label"><Text slug="general.true" /></div>
                        </div>
                    </div>
                </div>
            </ResponsiveElement>
        );
    }
}

export default AnswerHandle;