import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as popupActions from 'redux/actions/popupActions';

import ResponsiveElement from 'components/general/ResponsiveElement';

import './Popup.scss';

const mapStateToProps = (state, ownProps) => {
	return {
		...state.popupReducer
	}
}

const mapDispatchToProps = dispatch => ({
    closePopup: () => dispatch(popupActions.closePopup()),
});

class Popup extends Component {
	static defaultProps = {
		overlayOpacity: 0.65,
		clickBlock: true,
		overlayClick: false,
		contentStyle: {},
		autoAdjust: true,
	};

	constructor(props) {
		super(props);

		this.content = null;
	}

	render() {
		let overlayStyle = {
			opacity: this.props.overlayOpacity,
		}

		if(!this.props.clickBlock) {
			overlayStyle['pointerEvents'] = 'none';
		}

		let contentStyle = {
			padding: '6em 5.5em 7em',
			...this.props.contentStyle,
		}

		return (
			<div className={"popup" + (this.props.isOpened ? " active" : "")}>
				<div className="popup-overlay" onClick={this.onOverlayClick.bind(this)} style={overlayStyle}></div>
				<ResponsiveElement
					{...this.getResponsiveElementProps()}
					className="popup-container"
					autoAdjust={this.props.autoAdjust ? 'popup-inner-content' : null}
					key={this.props.uniqid}
				>
					{this.renderTitle()}
					<div className="popup-content" style={contentStyle} >
						{ this.props.autoAdjust ?
							<div className="popup-inner-content" ref={ref => this.content = ref}>
									{this.props.content}
							</div> :
							this.props.content
						}
					</div>
					{this.renderActions()}
				</ResponsiveElement>
			</div>
		);
	}
	
	/**
	 * Events
	 */

	onOverlayClick(e) {
		if(this.props.overlayClick) {
			this.props.closePopup();
		}
	}

	/**
	 * Renders
	 */

	renderTitle() {
		if(this.props.title) {
			return (
			<div className="popup-title-container">
				<div className="popup-title">
					<span>{this.props.title}</span>
				</div>
			</div>);
		}

		return null;
	}

	renderActions() {
		if(this.props.actionButtons.length > 0) {
			let actions = [];

			for(let i = 0; i < this.props.actionButtons.length; i++) {
				let button = this.props.actionButtons[i];

				let options = {
					type: 'custom',
					content: null,
					action: () => {},
					...button
				}

				actions.push(<div className={"popup-action action-" + options.type} onClick={options.action} key={i}>{options.content}</div>)
			}

			return (<div className="popup-actions">{actions}</div>)
		}

		return null;
	}

	 /**
	  * Styles
	  */

	getPopupStyle() {
		let size = this.calculateSize();
		let position = this.calculatePosition(size);
		
		let style = {
			'position': 'absolute',
			...this.pixelate({...size}),
			...this.pixelate({...position}),
		}

		if(this.props.setFontSize) {
			style['fontSize'] = (size.height / 100) + 'px';
		}

		return style;
	}

	/**
	 * Props
	 */
	getResponsiveElementProps() {
		let defaultProps = {
			ratio: 1038 / 892,
			width: '40%',
			maxWidth: '80%',
			maxHeight: '80%',
			top: '50%',
			left: '50%',
			anchorX: '50%',
			anchorY: '50%',
			cssPosition: 'absolute',
			fontSizeRef: 'window-height',
		}

		return {
			...defaultProps,
			...this.props.responsiveElementOptions,
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Popup);