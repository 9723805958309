import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import * as strapi from 'utils/strapi';

class Text extends Component {
	static propTypes = {
		slug: PropTypes.string,
	};

	static defaultProps = {
		parse: false,
	}

	render() {
		let {slug} = this.props;
		let text = strapi.data.getText(slug);

		if(this.props.parse) {
			return (
				<Fragment dangerouslySetInnerHTML={{__html: strapi.mdToHtml(text)}} />
			);
		}

		return text.split('\n').map((item, key) => <Fragment key={key}>{item}<br/></Fragment>);
	}
}

export default Text;