import React, { Component }  from 'react';

import ResponsiveElement from 'components/general/ResponsiveElement';
import * as locale from 'utils/locale';

import './CodeDisplay.scss';

class CodeDisplay extends Component {
	render() {
        return (

            <ResponsiveElement className={"code-display code-display-" + locale.getLang()} ratio={439/749} left="2%" height="70%" maxHeight="850px" top="52%" anchorY="50%" maxWidth="22%">
                {this.renderCombination()}
            </ResponsiveElement>
        );
    }

    renderCombination() {
        let {combination} = this.props;

        let combinationDiv = [];

        for(let i = 0; i < combination.length; i++) {
            let className = "combination-number";
            if(i < this.props.currentNumber) {
                className += " completed";
            }

            combinationDiv.push(<div className={className} key={i}>{combination[i]}</div>);
        }

        return (
            <div className="combination">
                {combinationDiv}
            </div>
        );
    }
}

export default CodeDisplay;