import React, { Component }  from 'react';
import { withRouter } from "react-router-dom";

import TransitionLink from 'components/general/TransitionLink';
import ShapedDiv from 'components/general/ShapedDiv';
import Text from 'components/general/Text';
import * as locale from 'utils/locale';

import './GameHeader.scss';
import GoButton from '../../general/GoButton';

class GameHeader extends Component {
	render() {
		return (
			<ShapedDiv
				backgroundColor="#ffffff"
				bottomLeft={[0, 92]}
				topLeft={[0, 15]}
				animationRange={0.5}
				animate={false}
				topLeftRange={[0, 0]}
				topRightRange={[0, 0]}
				bottomLeftRange={[0, 0]}
				bottomRightRange={[0, 0]}
			>
				<div className="static-game-header">
					<div className="container">
						<div className="content">
							<div className="text">
								<div className="logo">
									<img src={"/images/logos/go-secondaire_logo_bleu_"+locale.getLang()+".svg"} alt="Go secondaire"/>
									{/*<div className="credits"><Text slug="home.header.credit"/></div>*/}
								</div>
								<h1><Text slug="home.header.title"/></h1>
								<p><Text slug="home.header.subtitle"/></p>
								<p className="static-game-header__description"><Text slug="home.header.description"/></p>
							</div>
							<div className="hand">
								<div className="hand__container">
									<img className="hand__phone" src="/images/static/hero_hand.png" alt="Main telephone"/>
									<TransitionLink className="btn-play btn-grow-hover" to={locale.getUrl('/jeu')} onTransitionStart={this.props.onTransitionStart}>
										<GoButton color="blue" />
									</TransitionLink>
								</div>
							</div>
						</div>
					</div>
				</div>
			</ShapedDiv>
		);
	}
}

export default withRouter(GameHeader);