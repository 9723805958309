import React, { Component }  from 'react';
import { Route, Switch, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedo } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux';

import gameComponentList from 'utils/games/gameComponentList';
import * as siteActions from 'redux/actions/siteActions';
import * as locale from 'utils/locale';

import Map from 'components/game/Map/Map';
import Menu from 'components/game/Menu/Menu';
import FullScreenButton from 'components/game/FullScreenButton/FullScreenButton';
import Text from 'components/general/Text';

import './Game.scss';

const mapStateToProps = (state, ownProps) => {
	return {
        games : state.gameReducer.games,
        menuOpened : state.gameReducer.menu.opened,
	}
}

const mapDispatchToProps = dispatch => ({
    setUrls: (urls) => dispatch(siteActions.setUrls(urls)),
});

class Game extends Component {
    render() {
        return (
            <div className="game" id="game">
                <div className="overlay">
                    <div className="iconContainer">
                        <div className="phone">
                            <FontAwesomeIcon icon={faRedo} />
                        </div>
                        <div className="hint">
                            <Text slug="general.horizontal"/>
                        </div>
                    </div>
                </div>
                <Switch>
                    <Route exact path={locale.getUrl('/jeu')} component={Map} />
                    {this.createGameRoutes()}
                </Switch>
                <Menu />
                <FullScreenButton />
            </div>
        );
    }

    createGameRoutes() {
        let routes = [];

        for(var gameSlug in gameComponentList) {
            let slug = gameSlug;
            routes.push(<Route exact path={locale.getUrl('/jeu/:slug', { slug: gameSlug })} key={gameSlug} render={(props) => {
                let GameComponent = gameComponentList[slug];
                let lang = locale.getLang();

                // Find the current game
                this.props.games.forEach((game) => {
                    if(game.urlSlug[lang] === slug) {
                        var slugParams = {};

                        for(let i in game.urlSlug) {
                            slugParams[i] = {
                                slug: game.urlSlug[i],
                            }
                        }

                        this.props.setUrls(locale.getUrls('/jeu/:slug', slugParams));
                    }
                });

                return <GameComponent />;
            }} />);
        }

        return routes;
    }

    componentDidMount() {
        window.scrollTo(0,1);
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Game));