import React, { Component } from 'react';
import { TweenLite, Elastic, Power4, Back } from "gsap/TweenMax";
import { TimelineLite } from 'gsap/TimelineLite';

export class AnimatedElements extends Component {
	/*static propTypes = {
		onTransitionStart: PropTypes.func,
	};*/

	static defaultProps = {
		animation: 'scale',
		animatedClass: null,
		durationIn: 1,
		durationOut: 0.8,
		skipIntro: false,
		autoStart: false,
	};

	constructor(props) {
		super(props);
		this.container = null;
	}

	getTarget() {
		if(this.props.animatedClass) {
			return this.container.getElementsByClassName(this.props.animatedClass)[0];
		}

		return this.container;
	}

	componentDidMount() {
		if(!this.props.skipIntro) {
			TweenLite.set(this.getTarget(), this.getInitialeOptions());
		}

		if(this.props.autoStart) {
			this.show();
		}
		
		if(this.props.animatedElements) {
			this.props.animatedElements.push(this);
		}
	}

	componentWillUnmount() {
		if(this.props.animatedElements) {
			for( var i = 0; i < this.props.animatedElements.length; i++){ 
				if (this.props.animatedElements[i] === this) {
					this.props.animatedElements.splice(i, 1); 
					break;
				}
			}
		}
	}

	getInitialeOptions() {
		let { animation } = this.props;

		switch(animation) {
			case 'scale' :
				return {scale: 0, transformOrigin:"center center"};
			case 'slide-down' :
				return {y: -window.innerHeight};
			case 'slide-up' :
				return {y: window.innerHeight};
			case 'slide-right' :
				return {x: -window.innerWidth};
			case 'slide-left' :
				return {x: window.innerWidth};
			case 'fade' :
				return {opacity: 0};
			default:
				break;
		}
	}

	show(tl = null, offset = 0.8) {
		let { animation, durationIn } = this.props;

		let options = {};

		tl = tl === null ? TweenLite : tl;

		switch(animation) {
			case 'scale' :
				options = {scale: 1, transformOrigin:"center center", ease: Elastic.easeOut};
				break;
			case 'slide-down' :
			case 'slide-up' :
				options = {y: 0, ease: Power4.easeOut};
				break;
			case 'slide-right' :
			case 'slide-left' :
				options = {x: 0, ease: Power4.easeOut};
				break;
			case 'fade' :
				options = {opacity: 1};
				break;
			default:
				break;
		}

		if(tl !== null) {
			tl.to(this.getTarget(), durationIn, options, "-=" + offset);
		} else {
			TweenLite.to(this.getTarget(), durationIn, options);
		}
	}

	hide(tl = null, offset = 0.4) {
		let { animation, durationOut } = this.props;

		let options = {};

		tl = tl === null ? TweenLite : tl;

		switch(animation) {
			case 'scale' :
				durationOut = durationOut * 0.6;
				offset = offset * 0.6;
				options = {...this.getInitialeOptions(), ease: Back.easeIn};
				break;
			case 'slide-down' :
			case 'slide-up' :
				options = {...this.getInitialeOptions(), ease: Power4.easeIn};
				break;
			case 'slide-right' :
			case 'slide-left' :
				options = {...this.getInitialeOptions(), ease: Power4.easeIn};
				break;
			case 'fade' :
				options = {...this.getInitialeOptions()};
				break;
			default:
				break;
		}

		if(tl !== null) {
			tl.to(this.getTarget(), durationOut, options, "-=" + offset);
		} else {
			TweenLite.to(this.getTarget(), durationOut, options);
		}
	}

	render() {
		let className = 'animated-element';
		className += ' animation-' + this.props.animation;

		return (
			<div className={className} ref={container => this.container = container}>
				{this.props.children}
			</div>
		);
	}
}

export function animate(animatedElements, options, action='show') {
	options = {
		onComplete: () => {},
		offset: action === 'show' ? 0.8 : 0.6,
		delay: action === 'show' ? 1 : 0,
		autoplay: true,
		...options
	};

	let tl = new TimelineLite({onComplete: options.onComplete, delay: options.delay});

	animatedElements.forEach((el, i) => {
		if(action === 'show') {
			el.show(tl, options.offset);
		} else if(action === 'hide') {
			el.hide(tl, options.offset);
		}
	});

	if(options.autoplay) {
		tl.play();
	}

	return tl;
}

export function animateIn(animatedElements, options) {
	return animate(animatedElements, options, 'show');
}

export function animateOut(animatedElements, options) {
	return animate(animatedElements, options, 'hide');
}

